import React, { useState, useEffect, useRef } from "react"
import GlobalNav from './Nav'
import classNames from 'classnames'
import { dimensions } from '../../globalFunctionObjects.js'

const handleNavTriggerClick = () => {     
    document.querySelector('body').classList.toggle('navigation-is-open')    
}

const GlobalHeader = (props) => {

    const  [stickyActive, 
            setStickyActive  
            ] = useState(0)

    const headerEl = useRef()

    const checkStickyState = () => {

        if (headerEl && headerEl.current) {
            if (window.pageYOffset > 0 && window.pageYOffset >= headerEl.current.clientHeight) {
                document.querySelector('body').classList.add('js-is-scrolled')  
            } else {
                document.querySelector('body').classList.remove('js-is-scrolled') 
            }

            if (dimensions.scrollDirection === 'down' ||
                dimensions.scrollPosInLastViewablePane === true || 
                dimensions.scrollPosIsHalfwayThroughTopPane === false
            ) {
                setStickyActive(0)
            } else if (dimensions.scrollDirection === 'up' &&
                dimensions.scrollPosIsHalfwayThroughTopPane === true
            ) {
                setStickyActive(1)                
            } else if (dimensions.scrollDirection === 'up' && 
                dimensions.scrollPosIsHalfwayThroughTopPane === false
            ) {
                setStickyActive(0)
            }
        }
    }

    useEffect(() => {
        // code to run after render goes here        
        // always add event listeners within useEffect so it's run after render
        // and also removed (unbound) on unMount        
        window.addEventListener('load', checkStickyState)   
        window.addEventListener('resize', checkStickyState)      
        window.addEventListener('scroll', checkStickyState) 
    })

    return (
        <header>
            <GlobalNav />
            <div
                className={
                    classNames(
                        'site-header',
                        'js-fixed-header',
                        {
                        'js-fixed-header--active': stickyActive,
                        'js-fixed-header--show': stickyActive
                    })
                }
                ref={headerEl}
            >
                <div className="site-header__inner">
                    <a href="/" className="logo black svg">
                        <img
                            src={`${process.env.PUBLIC_URL}/files/vec_logo.svg`}
                            alt="Veronica Camargo :: Graphic Designer &amp; Web Developer"
                        />
                    </a>
                    <a href="/" className="logo white svg">
                        <img
                            src={`${process.env.PUBLIC_URL}/files/vec_logo_white.svg`}
                            alt="Veronica Camargo :: Graphic Designer &amp; Web Developer"
                        />
                    </a>
                    <a href="/" className="logo-icon svg">
                        <img
                            src={`${process.env.PUBLIC_URL}/files/vec_icon.png`}
                            alt="Veronica Camargo :: Graphic Designer &amp; Web Developer"
                        />
                    </a>
                    <div className="nav-trigger">
                        <button
                            className="nav-trigger__link js-nav-trigger"
                            onClick={handleNavTriggerClick}
                        > 
                            <span className="cd-nav-icon"></span>
                        </button>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default GlobalHeader