import React, {useState, useEffect} from "react"
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {useParams} from "react-router-dom"
import classNames from 'classnames'
import GlobalFooter from '../Global/Footer'
import GlobalLoading from '../Global/Loading'
import PortfolioAssetsHandler from './AssetsHandler'
import PortfolioBanner from './Banner'
import PortfolioInfo from './Info'
import PortfolioInfoButton from './InfoButton'
import PortfolioPrevNext from './PrevNext'

const PortfolioIndex = (props) => {
    const [portItem, setPortItem] = useState(null, null)
    const { portfolioItemSlug } = useParams()

    /* there may be a better way to achieve querying a single entry that matches on
    a field other than ID, but for now, I can't find a better way than the following */
    const queryPortfolioItemBySlug = `
    {
        portfolioItemCollection(where: {
            portfolioItemDetailPageSlug: "`+portfolioItemSlug+`"
        }, limit: 1)
        {   
            items {
                portfolioItemName
                portfolioItemClientName {
                    json
                }
                portfolioItemShortHandle
                portfolioItemDetailPageSlug
                portfolioItemClientDescriptionText {
                    json
                }
                portfolioItemRoleDescriptionText {
                    json
                }
                portfolioItemServicesDescriptionText {
                    json
                }
                portfolioItemDetailIcon {
                    url
                }  
                portfolioItemDetailIconClass  
                portfolioItemHomeSlideImage {
                    url
                }
                portfolioItemHomeSlideTitle {
                    json
                }
                portfolioItemDetailPageTitle {
                    json
                }
                portfolioItemRelationsCollection(limit: 20) {
                    items {
                        ... on PortfolioItemPullQuoteAsset { 
                            assetType
                            portfolioItemAssetQuoteText {
                                json
                            }
                            portfolioItemAssetQuoteAttributeText {
                                json
                            }
                            portfolioItemAssetQuoteAttributeTitleText {
                                json
                            }
                        }
                        ... on PortfolioItemAssetImageSet {     
                            assetType
                            portfolioItemIsVerticallyScrollable
                            sectionHeading {
                                json
                            }
                            showCaptions
                            wrapperClassnames
                            optionalBackgroundImage {
                                url
                            }
                            portfolioItemAssetImageSetImagesCollection(limit: 20) {
                                items {
                                    url
                                    title
                                    description                                  
                                }                                
                            }                            
                        }
                        ... on ProjectAccolade { 
                            assetType
                            mainImage {
                                url
                                title
                            }
                            accoladeItemsCollection(limit: 2) {
                                items {
                                    heading
                                    description {
                                        json
                                    }                                    
                                }  
                            }
                        }
                        ... on BrandAssets {      
                            assetType
                            sectionHeading {
                                json
                            }
                            logo {
                                url
                                title
                            }
                            brandPalettesCollection(limit: 4) {
                                items {
                                    paletteLabel
                                    paletteColorsJson                                    
                                }                                
                            }
                            brandFontsCollection(limit: 4) {
                                items {
                                    fontLabel
                                    fontImage {
                                        url
                                        title
                                    }                                    
                                }                                
                            }  
                            brandIconsCollection(limit: 4) {
                                items {                                    
                                    iconImage {
                                        url
                                        title
                                    }                                    
                                }                                
                            }
                        }
                        ... on PortfolioItemWebsiteSuperGroup {
                            assetType
                            wrapperClassnames
                            showCaptions
                            superGroupCaptionText
                            superGroupSectionHeading {
                                json
                            }
                            websiteSubGroupsCollection(limit: 3) {                                
                                items { # THESE ARE WEBSITE GROUPS
                                    ... on PortfolioItemAssetImageWebsiteGroup { 
                                        websiteGroupType 
                                        websiteGroupItemsCollection(limit: 2) {
                                            items {
                                                isVerticallyScrollable
                                                websiteGroupItemType
                                                websiteGroupItemImage {
                                                    url
                                                    title
                                                    description
                                                }
                                            } 
                                        }             
                                    }
                                }
                            }                            
                        }                        
                    }
                }                
            }       
        }
    }
    `
    
    useEffect(() => {
      window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/qxgsbxqyjsra/`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer kHvjHpwOCN2ugb3mTXreQ_IRWmvvTYQ9jfQt27N7J9I",
      },
      body: JSON.stringify({ query: queryPortfolioItemBySlug }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
  
        if (errors && errors.length > 0) {
            console.error(errors)   
        } else if (data) {
            // rerender the entire component with new data
            setPortItem(data.portfolioItemCollection.items[0])
           // console.log('Portfolio data: ')   
           // console.log(data.portfolioItemCollection.items[0])   
        }   
        document.querySelector('body').classList.add('detail-page')
        setTimeout( () => document.querySelector('body').classList.add('bare-display'), 300)
        setTimeout( () => document.querySelector('body').classList.add('loaded'), 600)
        setTimeout( () => document.querySelector('body').classList.add('js-begin-blur'), 2000)
      });
    }, [setPortItem, queryPortfolioItemBySlug])
    
    /**
     * NOTE 
     * items listed in the above dependency array passed to useEffect
     * will determine if/when a re-render occurs
     */
  
    // show a loading screen case no data in response
    if (!portItem) {
        return (
            <GlobalLoading />
        )
    }

    
    return (       
        <HelmetProvider>
            <Helmet>
                <title>{portItem.portfolioItemName} :: Veronica Camargo — Web developer &amp; designer :: New York</title>
            </Helmet>
            <div className="site-container">
                <section className="site-content">
                    <div className={classNames(                 
                        'site-content__inner',
                        'scope--'+portItem.portfolioItemShortHandle
                    )}>
                        <PortfolioInfo
                            portItem={portItem} dimensions={props.dimensions}
                        />
                        <article className="project">
                            <PortfolioBanner
                                portItem={portItem}
                            />
                            <div className="project-assets">
                                <PortfolioInfoButton
                                    portItem={portItem}
                                />
                                <PortfolioAssetsHandler
                                    portAssets={portItem.portfolioItemRelationsCollection.items}
                                />                               
                            </div>  
                            <PortfolioPrevNext
                                currentPageSlug={portItem.portfolioItemDetailPageSlug}
                            />      
                            <GlobalFooter />
                        </article>
                    </div>
                </section>                
            </div>
        </HelmetProvider>
    )
}

export default PortfolioIndex