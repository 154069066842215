import React, {useState, useEffect} from "react"
import {Helmet, HelmetProvider} from 'react-helmet-async'
import GlobalLoading from '../Global/Loading'
import HomeSlideshow from '../Home/Slideshow'
import HomeSlideshowNav from '../Home/SlideshowNav'
import HomeSplash from '../Home/Splash'

/***
 * STILL TO DO:
 * —— Revisit dynamic OG tags using Helmet 
 */

/***
 * Note: this query const can be named whatever
 * but according to GraphQL docs, the prop passed to the request must be named "query"
 * therefore, below, note that we've passed this var as...
 * ----   body: JSON.stringify({ query: queryPortfolioCollection }) * 
 * otherwise you can name this const query and simply pass query below to the API, e.g., 
 * ----   body: JSON.stringify({ query })
 * 
 * The GraphQL Playground app within Contentful is a very helpful
 * tool if you need to test queries and see what the schema is.
 */
const queryPortfolioCollection = `
{
  portfolioItemCollection(order: [portfolioItemHomeOrderBy_ASC]) {
    items {
      portfolioItemClientName {
        json
      }
      portfolioItemShortHandle
      portfolioItemDetailPageSlug
      portfolioItemNavIcon {
        url
      }      
      portfolioItemHomeSlideImage {
        url
      }
      portfolioItemHomeSlideTitle {
        json
      }
      portfolioItemDetailPageTitle {
        json
      }
    }
  }
}
`

const HomeIndex = (props) => {

  const [portItems, setPortItems] = useState(null, null)

  useEffect(() => {
    window
    .fetch(`https://graphql.contentful.com/content/v1/spaces/qxgsbxqyjsra/`, {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer kHvjHpwOCN2ugb3mTXreQ_IRWmvvTYQ9jfQt27N7J9I",
    },
    body: JSON.stringify({ query: queryPortfolioCollection }),
    })
    .then((response) => response.json())
    .then(({ data, errors }) => {
      if (errors && errors.length > 0) {
          //console.error(errors)
      } else if (data) {
          // rerender the entire component with new data
          setPortItems(data.portfolioItemCollection.items)
      }  
      setTimeout( () => document.querySelector('body').classList.add('bare-display'), 300)
      setTimeout( () => document.querySelector('body').classList.add('loaded'), 600)
      document.querySelector('body').classList.add('home-page')
    });
  }, [setPortItems])

  // show a loading screen case the data hasn't arrived yet
  if (!portItems) {
      return (
          <GlobalLoading />
      )
  }

  return (
    <HelmetProvider>
      <div className="site-container">
        <Helmet>
          <title>Veronica Camargo — Web developer &amp; designer :: New York</title>
        </Helmet>
        <section className="site-content">
            <div className="site-content__inner">
                <HomeSplash />
                <HomeSlideshowNav portItems={portItems} scrollToWork={props.scrollToWork} />
                <HomeSlideshow portItems={portItems}  />              
            </div>
        </section>
      </div>
    </HelmetProvider>
  )
}

export default HomeIndex