import React, { useEffect } from "react"

const GlobalLoading = (props) => {

  let message = null // No display or message at all if success

  useEffect( () => {   
    setTimeout( () => document.querySelector('body').classList.add('bare-display'), 300)
    setTimeout( () => document.querySelector('body').classList.add('loaded'), 600)  
  })

  if (!props.status || props.status==='error') {
    message = <div>
                <img className="missing-icon" src="/files/vec_icon.svg" alt="Veronica Camargo" />
                <p>Whoops! Something went wrong.</p>
              </div> 
  } else if (props.status==='loading') {
    message = <div>
                <div className="lds-ripple"><div></div><div></div></div>
              </div>
  }  

  if (!props.status || props.status!=='success') {
    return (
      <div className={'page-status '+props.status}>
        <div className="page-status__inner">
          {message}   
        </div>
      </div>
    )
  }
  return null // no display if success or some other weirdo state
}

export default GlobalLoading