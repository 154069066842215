import React from "react"
import PortfolioCaption from './Caption'
import PortfolioWebsiteGroup from './WebsiteGroup'
import PortfolioSectionHeading from './SectionHeading'

/*** begin STATEFUL FUNCTIONS.. */
const PortfolioWebsiteSuperGroupAsset = (props) => {

    if (props.groupItems) {  

        let finalClassnames = []

        if (props.baseClassnames) {
            props.baseClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }

        if (props.addlWrapperClassnames) {
            props.addlWrapperClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }  

        if (props.assetType==='website-supergroup--tablet-tablet') {
            finalClassnames.push('tablet-tablet')
        } else if (props.assetType==='website-supergroup--tablet-phone') {
            finalClassnames.push('tablet-phone')
        } else if (props.assetType==='website-supergroup--tablet-phone-phone') {
            finalClassnames.push('tablet-phone-phone')
        }

        if (!props.groupItems) {
            return (null)
        }

        if (props.showCaptions===true && props.superGroupCaptionText) {
            return (
                <div className={finalClassnames.join(' ')}>
                    <PortfolioSectionHeading
                        sectionHeading={props.sectionHeading}                    
                    /> 
                    <div className="image__inner">
                        <PortfolioCaption caption={props.superGroupCaptionText} />                    
                        {props.groupItems.map((item, i) =>
                            <PortfolioWebsiteGroup
                                key={i}
                                parentGroupType={item.websiteGroupType}
                                itemsInGroup={item.websiteGroupItemsCollection.items}
                                baseClassnames={['image-website-group__wrap']}
                                additionalClassnames={item.additionalClassnames}
                                showCaptions={props.showCaptions}
                            />
                        )}
                    </div>
                </div>
            )
        }
        
        return (
            <div className={finalClassnames.join(' ')}>
                <PortfolioSectionHeading
                    sectionHeading={props.sectionHeading}                    
                /> 
                {props.groupItems.map((item, i) =>
                    <PortfolioWebsiteGroup
                        key={i}
                        parentGroupType={item.websiteGroupType}
                        itemsInGroup={item.websiteGroupItemsCollection.items}
                        baseClassnames={['image-website-group__wrap']}
                        additionalClassnames={item.additionalClassnames}
                        showCaptions={props.showCaptions}
                    />
                )}
            </div>
        )
    

        
        
    } else {
        return null;
    }
}

export default PortfolioWebsiteSuperGroupAsset