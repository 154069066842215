import React, {useState, useEffect} from "react"
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {useParams} from "react-router-dom"
import AboutBanner from './Banner'
import GlobalFooter from '../Global/Footer'
import GlobalImageGridSection from '../Global/ImageGridSection'
import GlobalLoading from '../Global/Loading'
import GlobalTabbedSection from '../Global/TabbedSection'
import { loadInEffect } from '../../globalFunctionObjects.js'


const AboutIndex = (props) => {
  const [pageSection, setPageSection] = useState(null)
  const [pageStatus, setPageStatus] = useState('loading', null)
  const { genPageSlug } = useParams()

  const queryGeneralPageBySlug = `
  {
    generalPageCollection(where: {
      generalPageSlug: "`+genPageSlug+`"
    }, limit: 1)
    {   
      items {
        generalPageSlug 
        generalPageTopSectionsCollection(limit: 1) {
          items {
            ... on GeneralPageBanner { 
              bannerImage {
                url
                title
              }
              bannerText {
                json
              }
              buttonText {
                json
              }
              buttonFileDestination {
                url
              }
              buttonUrlDestination
            }
          }
        }
        generalPageMainSectionsCollection(limit: 3) {
          items {
            ... on GeneralTabSectionGroup { 
              generalTabSectionsCollection(limit: 3) {
                items {
                  tabNavLabel
                  tabHandle
                  sectionAdditionalWrapperClasses
                  subSectionsCollection(limit: 5) {
                    items {
                      gridColumnClassname
                      sectionAdditionalWrapperClasses
                      subsectionContent {
                        json
                      }
                      childSectionsCollection(limit: 10) {
                        items {
                          gridColumnClassname
                          sectionAdditionalWrapperClasses
                          subsectionContent {
                            json
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on ImageGridSectionGroup {     
              imageGridSectionsCollection(limit: 10) {
                items {
                  sectionAdditionalWrapperClasses          
                  subSectionsCollection(limit: 10) {
                    items {
                      gridColumnClassname
                      sectionAdditionalWrapperClasses
                      subsectionContent {
                        json
                      }
                      imageFile {
                        url
                        title
                      }
                      childSectionsCollection(limit: 10) {
                        items {
                          gridColumnClassname
                          sectionAdditionalWrapperClasses
                          subsectionContent {
                            json
                          }
                          imageFile {
                            url
                            title
                          }
                        }
                      }
                    }
                  }    
                }
              }          
            }
          }
        }           
      }       
    }
  }
  `

  useEffect(() => {
    window
    .fetch(`https://graphql.contentful.com/content/v1/spaces/qxgsbxqyjsra/`, {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer kHvjHpwOCN2ugb3mTXreQ_IRWmvvTYQ9jfQt27N7J9I",
    },
    body: JSON.stringify({ query: queryGeneralPageBySlug }),
    })
    .then((response) => response.json())
    .then(({ data, errors }) => {
      if (errors && errors.length > 0) {
          console.error(errors)  
         setPageStatus('error')
      } else if (data) {
          // rerender the entire component with new data          
          setPageSection(data.generalPageCollection.items[0])    
          //console.log('Page data: ')    
          //console.log(data.generalPageCollection.items[0])
          setPageStatus('success')            
          document.querySelector('body').classList.add('detail-page')
          setTimeout( () => document.querySelector('body').classList.add('bare-display'), 300)
          setTimeout( () => document.querySelector('body').classList.add('loaded'), 800)
          setTimeout( () => document.querySelector('body').classList.add('js-begin-blur'), 800)
          loadInEffect.init()
          window.addEventListener('scroll', loadInEffect.reveal)
      }      
    });
  }, [setPageSection, queryGeneralPageBySlug]) 

  let [heroSection, tabSection, imageGridSection] = []

  if (pageSection) {
    heroSection = pageSection.generalPageTopSectionsCollection.items[0]
    tabSection = pageSection.generalPageMainSectionsCollection.items[0].generalTabSectionsCollection  
    imageGridSection = pageSection.generalPageMainSectionsCollection.items[1].imageGridSectionsCollection

    if (heroSection && tabSection && imageGridSection) {
      return (
        <HelmetProvider>
          <GlobalLoading status={pageStatus} />
          <div className="site-container">
            <Helmet>
              <title>Veronica Camargo — Web developer &amp; designer :: New York</title>
            </Helmet>
            <section className="site-content">
                <div className="site-content__inner">
                <article className="bio js-tab-wrap">        
                    <AboutBanner
                      bannerImageURL={heroSection.bannerImage.url}
                      bannerImageAltText={heroSection.bannerImage.title}
                      bannerText={heroSection.bannerText}
                      buttonText={heroSection.buttonText}
                      buttonFileDestination={heroSection.buttonFileDestination}
                      buttonUrlDestination={heroSection.buttonUrlDestination}
                    />                    
                    <GlobalTabbedSection
                      tabSections={tabSection.items}                
                    />
                    <GlobalImageGridSection
                      rows={imageGridSection.items}
                    /> 
                  </article>                  
                </div>
            </section>
          </div>
          <GlobalFooter />  
        </HelmetProvider>
      )
    }
  }
  return null
}

export default AboutIndex