import React from "react"

/**
 * TO DO...
 * —— bind hash change for work reveal button on load of Home page
 */

const handleWorkRevealClick = () => {    
  let bodyEl = document.querySelector('body')    
  bodyEl.classList.toggle('js-reveal-active')    
}

const HomeSplash = (props) => (
  <div className="home-splash">
    <button
      className="btn home-more__btn js-work-reveal"
      onClick={handleWorkRevealClick}
    >
        <span className="home-more__btn-text">Work</span>
    </button>
    <div className="home-splash__inner">
    <div className="home-splash__cell--top-left">
        <h1>Designer</h1>
    </div>
    <div className="home-more__bg"></div>
    <div className="home-splash__cell--bottom-right">
        <h1>Developer</h1>
    </div>
    </div>
  </div>     
)

export default HomeSplash