import React from "react"

const GlobalFooter = (props) => (
    <footer className="site-footer">
        <div className="site-footer__inner">
            <a href="/" className="site-footer__logo-icon svg">
                <img
                    src={`${process.env.PUBLIC_URL}/files/vec_icon.svg`}
                    alt="Veronica Camargo"
                />
            </a>
            <p className="site-footer__text">&copy; {new Date().getFullYear()} Veronica Camargo</p>
        </div>
    </footer>
)

export default GlobalFooter