import React from "react"


const Heading = (props) => {
    if (props.sectionHeading.json &&
        props.sectionHeading.json.content[0]
    ) {
        return (
            <h3 dangerouslySetInnerHTML={{ __html: props.sectionHeading.json.content[0].content[0].value }} />
        )
    }
    return null
}

const Body = (props) => {

    if (props.sectionHeading.json &&
        props.sectionHeading.json.content[1]
    ) {
        return (
            <p dangerouslySetInnerHTML={{ __html: props.sectionHeading.json.content[1].content[0].value }} />
        )
    }
    return null    
}



const PortfolioSectionHeading = (props) => {    
    if (props.sectionHeading && props.sectionHeading.json) {
        return  <div className="section-heading">
                    <Heading {...props}  />
                    <Body  {...props} />
                </div>
    }

    return null
}


export default PortfolioSectionHeading