import React from "react"
import { setup as reactContentfulImageSetup } from 'react-contentful-image'
import { getStaticPictureMarkup } from 'react-contentful-image'
import PortfolioCaption from './Caption'
import PortfolioSectionHeading from './SectionHeading'
import {
    responsiveImages, 
    responsiveImageMediaQueries as media
} from '../../globalFunctionObjects.js'

/*** begin STATELESS FUNCTIONS.. */
const renderWithOrWithoutWrap = (
    finalClassnameString, 
    pictureElementString, 
    optionalCaption,
    showCaptions,
    includeInnerWrap,
    sectionHeading
    ) => {

    if (!pictureElementString) {
        return null
    }

    if (finalClassnameString) {
        if (optionalCaption && showCaptions===true) {
            return <div className={finalClassnameString}>
                        <div className="image__inner">
                            <div dangerouslySetInnerHTML={{ __html: pictureElementString }}  />
                            <PortfolioCaption caption={optionalCaption} />
                        </div>                        
                    </div>
        }
        if (includeInnerWrap===true || sectionHeading) {
            return <div className={finalClassnameString}>
                        <div className="image__inner">
                            <PortfolioSectionHeading
                                sectionHeading={sectionHeading}                    
                            />
                            <div dangerouslySetInnerHTML={{ __html: pictureElementString }}/>       
                        </div>               
                    </div>
        }
        return <div 
                    className={finalClassnameString}
                    dangerouslySetInnerHTML={{ __html: pictureElementString }}        
                />             
    }    

    return <div dangerouslySetInnerHTML={{ __html: pictureElementString }} />
}


/*** begin STATEFUL FUNCTIONS.. */
const PortfolioSingleImageAsset = (props) => {

    if (props.imageURL) { 

        let finalClassnames = [],
            finalClassnameString = null

        if (props.baseClassnames) {
            props.baseClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }

        if (props.addlWrapperClassnames) {
            props.addlWrapperClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }  
        
        if (finalClassnames.length > 0) {
            finalClassnameString = finalClassnames.join(' ')
        }

        /**
         * if SVG, bypass the entire Contentful/React image output,
         * since it cannot handle SVG gracefully
         * (It will output the image but no matter what the target width
         * is specified as, it will be super tiny, like 72px wide)
         *  */ 
        const fileExtension = props.imageURL.split('.').pop()

        let imageElementString = null

        if (fileExtension==='svg') {
            imageElementString = '<img src="'+props.imageURL+'"} alt="'+props.altText+'" />'
        } else {
            let variants = responsiveImages.variants
            const imageSizes = responsiveImages.imageSizes
            
            reactContentfulImageSetup(media, variants)
            // reference: https://developer.aliyun.com/mirror/npm/package/react-contentful-image
                    
            const protocol_agnostic_contentfuL_file_url = props.imageURL.split('//')[1]
            // API docs claim it's agnostic, but it isn't — and react-contentful-image package prepends protocol

            const attrs = {
                src: '//'+protocol_agnostic_contentfuL_file_url,
                alt: props.altText,
                class: ''
            }

            imageElementString = getStaticPictureMarkup(attrs, imageSizes)            
        }

        return (
            renderWithOrWithoutWrap(
                finalClassnameString, 
                imageElementString, 
                props.caption, 
                props.showCaptions,
                props.includeInnerWrap,
                props.sectionHeading
            )
        )        
    }

    return null    
}

export default PortfolioSingleImageAsset