import React from "react"
import PortfolioSingleImageAsset from './SingleImageAsset'

/*** begin STATEFUL FUNCTIONS.. */
const PortfolioCoreBrandingFonts = (props) => {

    if (props.items && props.items.length > 0) {  

        let finalClassnames = [],
            finalClassnameString = null

        if (props.baseClassnames) {
            props.baseClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }

        if (props.addlWrapperClassnames) {
            props.addlWrapperClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        } 

        if (finalClassnames.length > 0) {
            finalClassnameString = finalClassnames.join(' ')
        }

        const getLoopContent = (props) => {

            let finalItemWrapperClassnames = [],
                    finalItemWrapperClassnameString = ''

            if (props.itemWrapperClassnames) {
                props.itemWrapperClassnames.forEach( addClass => {
                    finalItemWrapperClassnames.push(addClass)
                })
            }

            if (finalItemWrapperClassnames.length > 0) {
                finalItemWrapperClassnameString = finalItemWrapperClassnames.join(' ')
            }

            return props.items.map((item, index) => {
                return (
                    <div 
                        className={finalItemWrapperClassnameString}
                        key={index}
                    >
                        <PortfolioSingleImageAsset
                            assetType={props.assetType}
                            imageURL={item.fontImage.url}
                            altText={item.fontImage.title}
                            baseClassnames={['brand-assets__font-sample']}
                        />
                        <h3>{item.fontLabel}</h3>                        
                    </div>
                )
            }) 
        }
         
        return (
            <div className={finalClassnameString}>
                {getLoopContent(props)}
            </div>
        )
    } else {
        return null;
    }
}

export default PortfolioCoreBrandingFonts