import React from "react"
import { Link } from "react-router-dom"
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PortfolioSingleImageAsset from '../Portfolio/SingleImageAsset'

const bannerTextOptions = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>
  }
}

const buttonTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => children,
  }
}

const renderButton = (props) => {
  let destination = null,
      fileExtension = null,
      download = false,
      isExternal = false

  if (props.buttonUrlDestination && props.buttonUrlDestination) {
    destination = props.buttonUrlDestination
  }
  
  if (props.buttonFileDestination && props.buttonFileDestination.url) {
    destination = props.buttonFileDestination.url    
    isExternal = true
  }

  if (!destination) {
    return null
  }

  fileExtension = destination.split('.').pop()

  if (fileExtension==='pdf') {
    download = destination
  }

  if (isExternal) {
    return (
      <a
        href={destination}
        download={download}
        className="button"
      >
        {documentToReactComponents(props.buttonText.json, buttonTextOptions)}
      </a>
    )
  } else {
    return (
      <Link
        to={destination}
        target="_blank" 
        download={download} 
        className="button"
      >
        {documentToReactComponents(props.buttonText.json, buttonTextOptions)}
      </Link>      
    )
  }
}

const AboutBanner = (props) => {
  return (
    <div className="general-banner page-has-tabs">
        <div className="general-banner__col">
            <div className="general-banner__image">
              <PortfolioSingleImageAsset
                imageURL={props.bannerImageURL}
                altText={props.bannerImageAltText}
                baseClassnames={[]}
                addlWrapperClassnames={[]}
              />  
            </div>
        </div>
        <div className="general-banner__col">
          <div className="general-banner__text">
            {documentToReactComponents(props.bannerText.json, bannerTextOptions)}
            {renderButton(props)}
          </div>
        </div>
    </div>  
  )
}

export default AboutBanner