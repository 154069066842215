import React from "react"


/*** begin STATEFUL FUNCTIONS.. */
const PortfolioCoreBrandingPalettes = (props) => {

    if (props.items && props.items.length > 0) {  

        let finalClassnames = [],
            finalClassnameString = null

        if (props.baseClassnames) {
            props.baseClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }

        if (props.addlWrapperClassnames) {
            props.addlWrapperClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        } 

        if (finalClassnames.length > 0) {
            finalClassnameString = finalClassnames.join(' ')
        }

        const getLoopContent = (props) => {
            return props.items.map((item, index) => {

                let finalItemWrapperClassnames = [],
                    finalItemWrapperClassnameString = ''

                if (props.itemWrapperClassnames) {
                    props.itemWrapperClassnames.forEach( addClass => {
                        finalItemWrapperClassnames.push(addClass)
                    })
                }

                if (finalItemWrapperClassnames.length > 0) {
                    finalItemWrapperClassnameString = finalItemWrapperClassnames.join(' ')
                }

                return (
                    <div 
                        className={finalItemWrapperClassnameString}
                        key={index}
                    >
                        <h3>{item.paletteLabel}</h3>
                        {item.paletteColorsJson.hexColors.map((v, k) => {
                            return (
                                <span
                                    key={k}
                                    className="brand-assets__color--p"
                                    style={{backgroundColor: v}}
                                />
                            )
                        })}
                    </div>
                )
            })  
        }
        return (
            <div className={finalClassnameString}>
                {getLoopContent(props)}
            </div>
        )
    } else {
        return null;
    }
}

export default PortfolioCoreBrandingPalettes