import React from "react"
import PortfolioSectionHeading from './SectionHeading'
import PortfolioSingleBackgroundImageAsset from './SingleBackgroundImageAsset'

/*** begin STATELESS FUNCTIONS... */


/*** begin STATEFUL FUNCTIONS.. */
const PortfolioImageGridAsset = (props) => {

    if (props.gridItems) {   
        
        let finalClassnames = []

        if (props.baseClassnames) {
            props.baseClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        } 
        /** 
         * props.addlWrapperClassnames is not truly an array.
         * it's an array BEFORE being set as a prop
         * but becomes an object once set as prop
        */
        if (props.addlWrapperClassnames) {
            props.addlWrapperClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }    

        if (props.assetType==='grid--two-up') {
            finalClassnames.push('two-up')
        } else if (props.assetType==='grid--four-up') {
            finalClassnames.push('four-up')
        }

        return (
            <div className={finalClassnames.join(' ')}>  
                <PortfolioSectionHeading
                    sectionHeading={props.sectionHeading}                    
                /> 
                <div className="image-group__vctr">   
                    {props.gridItems.map((item, i) =>
                        <PortfolioSingleBackgroundImageAsset
                            key={i}
                            imageURL={item.url}
                            altText={item.title}
                            baseClassnames={['image', 'bg-cover', 'js-load-in']}
                        />
                    )}                
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default PortfolioImageGridAsset