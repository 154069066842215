import React from "react"
import GlobalFooter from '../Global/Footer'
import HomeSlide from './Slide'

const HomeSlideshow = (props) => (
  <div className="projects-gallery">
    <div className="projects-gallery__slide-wrap">
      {props.portItems.map((item, i) =>
        (item.portfolioItemHomeSlideImage && item.portfolioItemHomeSlideImage.url) ? <HomeSlide defaultActive={i===0 ? true : false} key={i} itemNum={i} {...item}  /> : ''  
      )}
      <GlobalFooter />
      </div>
  </div>
)

export default HomeSlideshow