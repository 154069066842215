import React from "react"


const handleClick = () => {         
    const bodyEl = document.querySelector('body') 
    if (!bodyEl.classList.contains('js-is-shifted')) { 
        bodyEl.classList.add('js-is-shifted')    
        
        bodyEl.scrollIntoView({behavior: "smooth"})
        //setTimeout( () => bodyEl.scrollIntoView({behavior: "smooth", block: "end"}), 600)
    } 
}

const PortfolioInfoButton = (props) => {
    if (props.portItem) {
        return (        
            <button
                className="btn project-info__btn"
                onClick={handleClick}>
                <span className="project-info__btn-text">Info</span>
            </button>
        )
    }
}

export default PortfolioInfoButton