import React from "react"
import { setup as reactContentfulImageSetup } from 'react-contentful-image'
import { getStaticPictureMarkup } from 'react-contentful-image'
import {
    responsiveImages, 
    responsiveImageMediaQueries as media
} from '../../globalFunctionObjects.js'

const variants = responsiveImages.variants
const imageSizes = responsiveImages.imageSizes

/*** begin STATEFUL FUNCTIONS.. */
const PortfolioWebsiteGroupItem = (props) => {

    if (props.imageURL) { 

        let finalClassnames = []

        if (props.baseClassnames) {
            props.baseClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }

        if (props.parentType==='ipad-pro') {
            finalClassnames.push('image-website-group__item--ipad-pro')
        } else if (props.parentType==='iphone-x') {
            finalClassnames.push('image-website-group__item--mobile-x')
        }          

        props.isVerticallyScrollable===true && finalClassnames.push('is-scrollable')  
        props.isVerticallyScrollable===true && finalClassnames.push('use-js-cursor')  

        if (props.addlWrapperClassnames) {
            props.addlWrapperClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }

        reactContentfulImageSetup(media, variants)
        // reference: https://developer.aliyun.com/mirror/npm/package/react-contentful-image
    
        const protocol_agnostic_contentfuL_file_url = props.imageURL.split('//')[1]
        // API docs claim it's agnostic, but it isn't — and react-contentful-image package prepends protocol

        const attrs = {
            src: '//'+protocol_agnostic_contentfuL_file_url,
            alt: props.altText,
            class: ''
        }

        const pictureElementString = getStaticPictureMarkup(attrs, imageSizes)

        let finalInnerHTML = pictureElementString

        if (props.additionalInnerMarkup) {
            finalInnerHTML += props.additionalInnerMarkup
        }

        if (props.imageCaption && props.showCaptions===true) {
            return (
                <div
                    className={finalClassnames.join(' ')}
                    dangerouslySetInnerHTML={{ __html: finalInnerHTML }}
                />
            )
        } else {
            return (
                <div
                    className={finalClassnames.join(' ')}
                    dangerouslySetInnerHTML={{ __html: finalInnerHTML }}
                />
            )
        }

        
    } else {
        return null;
    }
}

export default PortfolioWebsiteGroupItem