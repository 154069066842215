import React from "react"
import PortfolioSingleImageAsset from './SingleImageAsset'

/*** begin STATEFUL FUNCTIONS.. */
const PortfolioCoreBrandingIcons = (props) => {

    if (props.items && props.items.length > 0) {  

        let finalClassnames = [],
            finalClassnameString = null

        if (props.baseClassnames) {
            props.baseClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }

        if (props.addlWrapperClassnames) {
            props.addlWrapperClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        } 

        if (finalClassnames.length > 0) {
            finalClassnameString = finalClassnames.join(' ')
        }

        const getLoopContent = (props) => {

            let finalItemWrapperClassnames = []

            if (props.itemWrapperClassnames) {
                props.itemWrapperClassnames.forEach( addClass => {
                    finalItemWrapperClassnames.push(addClass)
                })
            }

            return props.items.map((item, index) => {
                return (
                    <PortfolioSingleImageAsset
                        key={index}
                        assetType={props.assetType}
                        imageURL={item.iconImage.url}
                        altText={item.iconImage.title}
                        baseClassnames={finalItemWrapperClassnames}
                    />
                )
            }) 
        }
         
        return (
            <div className={finalClassnameString}>
                {getLoopContent(props)}
            </div>
        )
    } else {
        return null;
    }
}

export default PortfolioCoreBrandingIcons