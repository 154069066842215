import React from "react"

const GlobalNav = (props) => {

    return (
        <div id="nav-overlay" className="navigation">
            <div className="navigation__wrapper js-navigation-wrapper">
                <div className="navigation__inner">
                    <nav>
                        <ul className="navigation__items">
                            <li>
                                <a href="/work" className="js-nav-link">featured work</a>
                            </li>
                            <li>
                                <a href="/about/bio" className="js-nav-link">about</a>
                            </li>
                            <li>
                                <a
                                    href={`${process.env.PUBLIC_URL}/files/Veronica_Camargo_resume_2021.pdf`}
                                    download={`${process.env.PUBLIC_URL}/files/Veronica_Camargo_resume_2021.pdf`}
                                    className="js-nav-link">resume
                                </a>
                            </li>
                        </ul>
                        <div className="navigation__contact-links">
                            <h3>connect with me</h3>
                            <a
                                className="button circle-icon"
                                href="mailto:veronica@veronicacamargo.com">
                                    <span className="icon-email-icon circle-icon-icon"></span>
                            </a>
                            <a
                                className="button circle-icon"
                                href="https://www.linkedin.com/in/veronicacamargo">
                                    <span className="icon-linkedin-icon circle-icon-icon"></span>
                            </a>
                        </div>
                    </nav>
                </div>            
            </div>
        </div>
    )
}

export default GlobalNav