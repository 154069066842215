import React from "react"
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PortfolioSingleImageAsset from '../Portfolio/SingleImageAsset'

/*** begin STATELESS FUNCTIONS */
const ParaWrap = ({children}) => <p>{children}</p>
//const UlWrap = ({children}) => children

/**
 * NOTE
 * structure for general tabs is as follows....
 * 
 * > generalTabSectionGroup
 *      (e.g., About page tabs — an entire module/section featuring tabs)
 * >>>> sections
 *      (e.g., Bio, Manifesto, Nitty Gritty — nodes representing one tab w/ corresponding content container)
 * >>>>>>>> subSections
 *      (e.g., Bio Heading, Bio Body, Nitty Gritty Row 1 — nodes that are entire "rows")
 * >>>>>>>>>>>> childSections
 *      (e.g., Skill level column 1 of 3 within Nitty Gritty Row 1 —  nodes that are "columns" within "rows")
 * 
 * ----------------------------------------------
 * 
 * structure for image grid sections is as follows....
 * 
 * > imageGridSectionGroup
 * >>>> imageGridSections
 * >>>>>>>> subSections
 * >>>>>>>>>>>> childSections
 */

const textOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <ParaWrap>{children}</ParaWrap>,
    //   [BLOCKS.UL_LIST]: (node, children) => <UlWrap>{children}</UlWrap>,    
    //   [BLOCKS.UL_LIST]: (node, children) => <UlWrap>{children}</UlWrap>,   
    },
};

const getSectionInnerContent = (innerItems, includeRowWrap=true) => {    

    let additionalClassnames = []

    if (includeRowWrap===true) {
        return innerItems.map((item, index) => {
            if (item.sectionAdditionalWrapperClasses) {
                item.sectionAdditionalWrapperClasses.forEach( addClass => {
                    additionalClassnames.push(addClass)
                })
            }

            return <div
                        key={index}
                        className={item.gridColumnClassname+' '+additionalClassnames.join(' ')}
                    >
                        {getSectionSubContent(item, false, index)}                        
                    </div>
            
        })
    } else {
        return innerItems.map((item, index) => {
            if (item.sectionAdditionalWrapperClasses) {
                item.sectionAdditionalWrapperClasses.forEach( addClass => {
                    additionalClassnames.push(addClass)
                })
            }
            return getSectionSubContent(item, false, index, additionalClassnames)
        })
    }    
}

const getSectionSubContent = (childcontent, includeRowWrap=true, index, additionalClassnames=[]) => { 
    // NOTE: it's either RTE content that gets displayed OR child nodes with column wrappers,
    // since in some cases nesting of grid containers is necessary.

    // RTE CONTENT
    if (childcontent.subsectionContent && childcontent.subsectionContent.json) {
        if (includeRowWrap===true) {
            return (
                <div
                    key={index}
                    className="row center case-0"
                >
                    <div
                        className={childcontent.gridColumnClassname+' '+additionalClassnames.join(' ')}
                    >
                        {documentToReactComponents(childcontent.subsectionContent.json, textOptions)}
                    </div>
                </div>
            )
        } else {
            return (
                <div
                    key={index}
                    className={childcontent.gridColumnClassname}
                >
                    {documentToReactComponents(childcontent.subsectionContent.json, textOptions)}
                </div>
            )
        }        
    }

    // AN IMAGE GRID SUB-SECTION (level 1 — may contain deeper/child sub-sections)
    if (childcontent.subSectionsCollection && 
        childcontent.subSectionsCollection.items && 
        childcontent.subSectionsCollection.items.length > 0) {        

        if (includeRowWrap===true) {
            return (
                <div
                    key={index}
                    className="row center case-1"
                >
                    {getSectionInnerContent(childcontent.subSectionsCollection.items, false)}
                </div>
            )
        } else {
            return (
                getSectionInnerContent(childcontent.subSectionsCollection.items, false)       
            )
        }
    }
  
    // A SUB-SECTION nested within a parent sub-section.
    // This may have deeper/nested/child sub-sections. // leave true
    if (childcontent.childSectionsCollection && 
        childcontent.childSectionsCollection.items && 
        childcontent.childSectionsCollection.items.length > 0
    ) {     
        let wrapper = true
            
        if (childcontent.childSectionsCollection.items.length===1) {
            wrapper = false //kinda hacky but in the case of the heading, no need to wrap twice
        }
        return (
            <div
                key={index}
                data-length={childcontent.childSectionsCollection.items.length}
                className="row center case-2"
            >
                {getSectionInnerContent(childcontent.childSectionsCollection.items, wrapper)}
            </div>
        )
    }


    // AN IMAGE FILE
    if (childcontent.imageFile && childcontent.imageFile.url) {     
        
        // override for now...
        includeRowWrap = false

        if (includeRowWrap===true) {
            return (
                <div
                    key={index}
                    className={childcontent.gridColumnClassname+' '+additionalClassnames.join(' ')}                    
                >  
                    <div className="client-list__logo">
                        <PortfolioSingleImageAsset
                            imageURL={childcontent.imageFile.url}
                            altText={childcontent.imageFile.title}
                            baseClassnames={['client-list__logo-img']}
                        />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="client-list__logo">
                    <PortfolioSingleImageAsset
                        key={index}
                        imageURL={childcontent.imageFile.url}
                        altText={childcontent.imageFile.title}
                        baseClassnames={['client-list__logo-img']}
                    />
                </div>
            )
        }        
    }
}

/*** begin STATEFUL FUNCTIONS */
const GlobalImageGridSection = (props) => {

    if (!props.rows) {
        return null
    }

    return (
        <section className="reveal-container--background client-list js-client-list">
            <div className="vert-center">
                <div className="container inner-pad">
                    {getSectionInnerContent(props.rows, false)}
                </div>
            </div>
        </section>  
    )
}

export default GlobalImageGridSection