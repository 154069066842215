import React from "react"
import PortfolioSingleImageAsset from './SingleImageAsset'
import PortfolioSectionHeading from './SectionHeading'
/*** begin STATELESS FUNCTIONS... */


/*** begin STATEFUL FUNCTIONS.. */
const PortfolioImageGroupStaggeredAsset = (props) => {

    if (props.gridItems) {   
        
        let finalClassnames = []

        if (props.baseClassnames) {
            props.baseClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        } 
        /** 
         * props.addlWrapperClassnames is not truly an array.
         * it's an array BEFORE being set as a prop
         * but becomes an object once set as prop
        */
        if (props.addlWrapperClassnames) {
            props.addlWrapperClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }    

        if (props.assetType==='group-staggered--left-initial') {
            finalClassnames.push('group-staggered--left-initial')
        } else if (props.assetType==='group-staggered--right-initial') {
            finalClassnames.push('group-staggered--right-initial')
        }

        return (
            <div className={finalClassnames.join(' ')}>
                <div className="group-staggered__inner">                     
                    <PortfolioSectionHeading
                        sectionHeading={props.sectionHeading}                    
                    /> 
                    <div className="group-staggered__items">            
                        {props.gridItems.map((item, i) =>
                            <PortfolioSingleImageAsset
                                key={i}
                                assetType={props.assetType}
                                imageURL={item.url}
                                altText={item.title}
                                baseClassnames={['image', 'no-pad', 'js-load-in']}   
                                addlWrapperClassnames={['group-staggered__item']}                     
                                caption={item.description}
                                showCaptions={props.showCaptions}
                                includeInnerWrap={true}
                            />      
                        )} 
                    </div>
                 </div>
            </div>
        )
    } else {
        return null;
    }
}

export default PortfolioImageGroupStaggeredAsset