import React, { useEffect, useRef } from "react"


const HomeSlideshowNav = (props) => {

  const galleryNav = useRef()

  const HomeSlideNavItems = (portItems) => {
    return (
      portItems.map((item, i) =>
        <li key={i}>
          <span          
            className="js-slide-trigger"
            data-handle={item.portfolioItemShortHandle}
            data-num={i}
            onClick={handleHomeSlideNavItemClick}
          >
            {item.portfolioItemNavIcon && item.portfolioItemNavIcon.url && <img src={item.portfolioItemNavIcon.url} alt={item.portfolioItemClientName} />}        
          </span>
        </li>
      )
    )
  }

  const scrollToGallery = () => {
    // add class to body when Work section revealed  
    document.querySelector('body').classList.add('js-gallery-active') 
    document.querySelector('body').classList.add('js-reveal-active') 

    if (galleryNav && galleryNav.current) {
      // scroll to projectsGalleryNav
      galleryNav.current.classList.add('trigger-scrollto')
      galleryNav.current.scrollIntoView({behavior: "smooth"})
    }
  }

  const handleHomeSlideNavItemClick = (e) => {    

    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault()

    // about the current elements
    let thisEl = e.currentTarget // gets <a> instead of the <img> nested within the <a>
    let targetHandle = thisEl.getAttribute('data-handle')
    let targetIndex = thisEl.getAttribute('data-num')
    let targetSlideEl = document.querySelector('.js-slide[data-handle="'+targetHandle+'"]')

    let allSlides = document.querySelectorAll('.js-slide')

    // remove (clear out) active class from all nav item(s)
    document.querySelectorAll('.js-slide-trigger').forEach(el => el.classList.remove('is-active'))

    // add active class to current (clicked) item
    thisEl.classList.add('is-active')

    // add class to body when Work section revealed  
    scrollToGallery()

    // remove (clear out) active class from all slides
    allSlides.forEach(el => el.classList.remove('is-active'))

    // make the targeted slide the active one
    if (targetSlideEl) {
      targetSlideEl.classList.add('is-active')
    }

    // loop all slides and determine classes for animation behavior
    allSlides.forEach( (slide, index) => {
      slide.classList.remove('before-active')
      slide.classList.remove('after-active')
      const indexAlign = index === 0 ? 1 : index++
      // because keys in this loop start at 1, not 0, and need to match mapped array keys

      // if this slide is not the target slide...
      if (!slide.classList.contains("is-active")) {
        // if this iteration's index is > than the active slide index
        if (indexAlign > targetIndex) {
          slide.classList.add('after-active')
        } else {
          slide.classList.add('before-active')
        }
      } 
    })
  }

  useEffect(() => {
      if (props.scrollToWork===true) {
        if (!document.querySelector('body').classList.contains('js-reveal-active')) {
          document.querySelector('body').classList.add('js-reveal-active')
        } 
        if (!document.querySelector('body').classList.contains('js-gallery-active')) {
          document.querySelector('body').classList.add('js-gallery-active')
        }         
        setTimeout( () => {                  
          document.querySelector('.projects-gallery__nav li:first-child > span').click()
           
        }, 1500)
        //cheating but it just would not work any other way I tried
      }
  })

  return (
    <nav 
      className="projects-gallery__nav"
      data-selected={props.portItems[0].portfolioItemShortHandle}
      ref={galleryNav}
    >
      <ul>{HomeSlideNavItems(props.portItems)}</ul>
    </nav>    
  )
}

export default HomeSlideshowNav