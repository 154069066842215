import React, { useState, useRef, useEffect } from "react"
import classNames from 'classnames'
import { BLOCKS } from '@contentful/rich-text-types';
/* REFERENCE: https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer */
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { dimensions } from '../../globalFunctionObjects.js'

/*** begin STATELESS FUNCTIONS */
const ParaWrap = ({children}) => <p>{children}</p>

const paraOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ParaWrap>{children}</ParaWrap>,
  },
};

const ListWrap = ({children}) => <div className="col-4"><ul>{children}</ul></div>

const listOptions = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => <ListWrap>{children}</ListWrap>,
  },
};

const bodyEl = document.querySelector('body')   

const handleInfoBtnClick = () => {   
    if (bodyEl.classList.contains('js-is-shifted')) {     
        bodyEl.classList.remove('js-is-shifted')    
    }
}

/*** begin STATEFUL FUNCTIONS */
const PortfolioInfo = (props) => {

    const  [activeTab, 
            setActiveTab   
            ] = useState('client', 0)
    
    const  [currentTabContent,
            setCurrentTabContent
            ] = useState(documentToReactComponents(props.portItem.portfolioItemClientDescriptionText.json, paraOptions),0)
            // (tab content defaults to the client description text)

    const tabContentWrapperEl = useRef()
    
    const showWrap = useRef()    

    useEffect(() => {
        // code to run after render goes here        
        // always add event listeners within useEffect so it's run after render
        // and also removed (unbound) on tear-down
        window.addEventListener('resize', dimensions.update)

    })

    const handletabBtnClick = (e) => {
        const tabVal = e.target.dataset.tab
        setActiveTab(tabVal)

        switch (tabVal) {            
            case 'role':
                // role text
                setCurrentTabContent(documentToReactComponents(props.portItem.portfolioItemRoleDescriptionText.json, paraOptions))       
            break;
            case 'services':
                // services text
                setCurrentTabContent(documentToReactComponents(props.portItem.portfolioItemServicesDescriptionText.json, listOptions))       
            break;
            default:
                // client text
                setCurrentTabContent(documentToReactComponents(props.portItem.portfolioItemClientDescriptionText.json, paraOptions))   
        }
    }

    if (props.portItem) {   
        return (
            <article className="project-brief">                
                <div className="project-narrative">
                    <button
                        className="btn project-info-back__btn"
                        onClick={handleInfoBtnClick}>
                            <span className="project-info-back__btn-text">Back</span>
                    </button>
                    <div className="container inner-pad">
                        <div className="row center">
                            <div className="col-12">
                                <h1>In brief</h1>
                            </div>
                        </div>
                        <div className="row center js-narrative-nav">
                            <div className="col-12">
                                <nav className="tabbed__nav has-rail">
                                    <ul className="tabbed__nav-list">
                                        <li className="tabbed__nav-item">
                                            <button
                                                className={
                                                    classNames(
                                                        {
                                                        'current': activeTab === 'client'
                                                    })
                                                }
                                                data-tab="client"
                                                onClick={handletabBtnClick}
                                            >
                                                <span className="hide-el--xs">The</span> Client
                                            </button>
                                        </li>
                                        <span className="tabbed__nav-divider"></span>
                                        <li className="tabbed__nav-item">
                                            <button
                                                className={
                                                    classNames(
                                                        {
                                                        'current': activeTab === 'role'
                                                    })
                                                }
                                                data-tab="role"
                                                onClick={handletabBtnClick}
                                            >
                                                My Role
                                            </button>
                                        </li>
                                        <span className="tabbed__nav-divider"></span>
                                        <li className="tabbed__nav-item">
                                            <button
                                                className={
                                                    classNames(
                                                        {
                                                        'current': activeTab === 'services'
                                                    })
                                                }
                                                data-tab="services"
                                                onClick={handletabBtnClick}
                                            >
                                                Services
                                            </button>
                                        </li>
                                    </ul>
                                </nav>  
                            </div>
                        </div>
                        <div className="row center">
                            <div className="col-12">
                                <div className="js-gradient-scroller">
                                    <div
                                        ref={showWrap}
                                        className={
                                            classNames(
                                                'tab-content',
                                                'show'
                                                )
                                        }
                                        data-tab-content="client"
                                    >                                        
                                        <div
                                        ref={tabContentWrapperEl}
                                        className={
                                            classNames(
                                                'tab-content__inner',
                                                {
                                                'services-list': (activeTab === 'services' ? true : false)
                                            })
                                        }                                   
                                        >
                                            {currentTabContent}
                                        </div>
                                    </div>  
                                </div>                         
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        )
    }
}

export default PortfolioInfo