import React from "react"
import PortfolioCoreBrandingFonts from './CoreBrandingFonts'
import PortfolioCoreBrandingIcons from './CoreBrandingIcons'
import PortfolioCoreBrandingPalettes from './CoreBrandingPalettes'
import PortfolioSingleImageAsset from './SingleImageAsset'
import PortfolioSectionHeading from './SectionHeading'

/*** begin STATEFUL FUNCTIONS.. ***/
const PortfolioCoreBrandingAsset = (props) => {

    if (props.logoImageURL) {

        let finalClassnames = ['brand-assets', 'js-load-in']

        if (props.addlWrapperClassnames) {
            props.addlWrapperClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }  

        return (
            <div className={finalClassnames.join(' ')}>
                <div className="brand-assets__inner">                
                    <PortfolioSectionHeading
                        sectionHeading={props.sectionHeading}                    
                    />              
                    <PortfolioSingleImageAsset
                        assetType={props.assetType}
                        imageURL={props.logoImageURL}
                        altText={props.logoImageAltText}
                        baseClassnames={['brand-assets__logo']}
                        addlWrapperClassnames={props.wrapperClassnames}
                    />   
                    <div className="brand-assets__standards">
                        <PortfolioCoreBrandingPalettes
                            items={props.paletteItems}
                            baseClassnames={['brand-assets__palettes']}
                            itemWrapperClassnames={['brand-assets__palette']}
                        />  
                        <PortfolioCoreBrandingFonts
                            items={props.fontItems}
                            baseClassnames={['brand-assets__fonts']}
                            itemWrapperClassnames={['brand-assets__font']}
                        />
                    </div>
                    <PortfolioCoreBrandingIcons
                        items={props.iconItems}
                        baseClassnames={['brand-assets__icons']}
                        itemWrapperClassnames={['brand-assets__icon']}
                    />                     
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default PortfolioCoreBrandingAsset