import React from "react"
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const AccoladeText = ({ children }) => <p>{children}</p>

const accoladeTextOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <AccoladeText>{children}</AccoladeText>,
    },
};

/*** begin STATEFUL FUNCTIONS.. */
const PortfolioProjectAccoladeItems = (props) => {

    if (props.items && props.items.length > 0) {  

        const getLoopContent = (props) => {

            let finalItemWrapperClassnames = [],
                    finalItemWrapperClassnameString = ''

            if (props.itemWrapperClassnames) {
                props.itemWrapperClassnames.forEach( addClass => {
                    finalItemWrapperClassnames.push(addClass)
                })
            }

            if (finalItemWrapperClassnames.length > 0) {
                finalItemWrapperClassnameString = finalItemWrapperClassnames.join(' ')
            }

            return props.items.map((item, index) => {
                return (
                    <div 
                        className={finalItemWrapperClassnameString}
                        key={index}
                    >
                        <h3>{item.heading}</h3>
                        {documentToReactComponents(item.description.json, accoladeTextOptions)}
                    </div>
                )
            }) 
        }
         
        return (
            getLoopContent(props)           
        )
    } else {
        return null;
    }
}

export default PortfolioProjectAccoladeItems