import React, { useState, useRef } from "react"
import classNames from 'classnames'
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

/*** begin STATELESS FUNCTIONS */
const ParaWrap = ({children}) => <p>{children}</p>
//const UlWrap = ({children}) => children

const textOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <ParaWrap>{children}</ParaWrap>,
    //   [BLOCKS.UL_LIST]: (node, children) => <UlWrap>{children}</UlWrap>,    
    //   [BLOCKS.UL_LIST]: (node, children) => <UlWrap>{children}</UlWrap>,   
    },
};

const getSectionInnerContent = (innerItems, includeRowWrap=true) => {    

    let additionalClassnamesString = ''

    if (includeRowWrap===true) {
        return innerItems.map((item, index) => {
            if (item.sectionAdditionalWrapperClasses) {
                additionalClassnamesString = item.sectionAdditionalWrapperClasses.join(' ')
            }

            return <div
                        key={index}
                        className={item.gridColumnClassname+' '+additionalClassnamesString}
                    >
                        {getSectionSubContent(item, true, index)}                        
                    </div>
            
        })
    } else {
        return innerItems.map((item, index) => {
            if (item.sectionAdditionalWrapperClasses) {
                additionalClassnamesString = item.sectionAdditionalWrapperClasses.join(' ')
            }
            return getSectionSubContent(item, false, index, additionalClassnamesString)
        })
    }    
}

const getSectionSubContent = (childcontent, includeRowWrap=true, index, additionalClassnamesString='') => { 
    // NOTE: it's either RTE content that gets displayed OR child nodes with column wrappers,
    // since in some cases nesting of grid containers is necessary.

    if (childcontent.subsectionContent && childcontent.subsectionContent.json) {
        if (includeRowWrap===true) {
            return (
                <div
                    key={index}
                    className="row center sub-section"
                >
                    <div
                        className={childcontent.gridColumnClassname+' '+additionalClassnamesString}
                    >
                        {documentToReactComponents(childcontent.subsectionContent.json, textOptions)}
                    </div>
                </div>
            )
        } else {
            return (
                <div
                    key={index}
                    className={childcontent.gridColumnClassname}
                >
                    {documentToReactComponents(childcontent.subsectionContent.json, textOptions)}
                </div>
            )
        }
        
    }
    if (childcontent.childSectionsCollection && childcontent.childSectionsCollection.items && childcontent.childSectionsCollection.items.length > 0) {        
        return (
            <div
                className="row center sub-sub-section"
            >
                {getSectionInnerContent(childcontent.childSectionsCollection.items, false)}
            </div>
        )
        // each item.subsectionContent.json
    }
}

/*** begin STATEFUL FUNCTIONS */
const GlobalTabbedSection = (props) => {

    const  [activeTab, 
            setActiveTab  
            ] = useState(props.tabSections[0].tabHandle, null)
    
    const  [currentTabContent,
            setCurrentTabContent   
            ] = useState(
                getSectionInnerContent(props.tabSections[0].subSectionsCollection.items),
                null
                )

    const tabContentWrapperEl = useRef()

    const handletabBtnClick = (e) => {
        const tabVal = e.target.dataset.tab // attr: "data-tab"
        setTabAndSection(tabVal)
    }

    const setTabAndSection = (tabVal) => {
        setActiveTab(tabVal)
        const tabNav = document.querySelector('.tabbed__nav')

        props.tabSections.forEach((section, index) => {           
            if (section.tabHandle===tabVal) {
                // console.log(getSectionInnerContent(section.subSectionsCollection.items))
                setCurrentTabContent(getSectionInnerContent(section.subSectionsCollection.items))
                
                // scroll to top of tabs?
                if (tabNav) {
                    tabNav.scrollIntoView({behavior: "smooth"})
                }                
            }
        })
    }

    const tabsLoop = props.tabSections.map((section, index) => {
        let finalContent = null,
            isCurrent = false,
            shouldHideDivider

        shouldHideDivider = ((index+1)===props.tabSections.length ? true : false)
        isCurrent = (activeTab===section.tabHandle ? true : false)

        finalContent = <li
                            className="tabbed__nav-item general"
                            key={index}
                        >
                        <button
                            data-tab={section.tabHandle}
                            onClick={handletabBtnClick}
                            className={isCurrent===true ? 'current' : 'undefined'}
                        >
                            {section.tabNavLabel}
                        </button>
                        <span
                            className={
                                classNames(
                                    'tabbed__nav-divider',
                                    {
                                    'is-hidden': shouldHideDivider,
                                    }
                                )
                            }
                        />
                    </li>   
        return (finalContent)       
    })

    if (!props.tabSections) {
        return null
    }

    return (
        <div>
            <nav className="tabbed__nav">
                <div className="container inner-pad">
                    <div className="row center">
                        <div className="col-10">
                            <ul className="tabbed__nav-list js-tab-nav">
                                {tabsLoop}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div
                className="main-content-reveal js-tab-content tab-content show"
            >
            <section
                className="reveal-container--foreground general-text"
            >
                <div
                    className="container inner-pad"
                    ref={tabContentWrapperEl}
                >
                    {currentTabContent}
                </div>
            </section>                    
            </div>
        </div>
    )
}

export default GlobalTabbedSection