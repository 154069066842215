import React from "react"
import PortfolioSingleImageAsset from './SingleImageAsset'
import PortfolioProjectAccoladeItems from './ProjectAccoladeItems'


/*** begin STATEFUL FUNCTIONS.. ***/
const PortfolioProjectAccolade = (props) => {

    if (props.accoladeItems && props.accoladeItems.length > 0) {

        let finalClassnames = ['project-accolade', 'has-bleed-bg', 'project-color-bg']

        if (props.addlWrapperClassnames) {
            props.addlWrapperClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }  

        return (
            <div className={finalClassnames.join(' ')}>
                <blockquote>
                    <div className="project-accolade__inner js-load-in">
                        <div className="container">
                            <div className="row center">
                                <PortfolioSingleImageAsset
                                    assetType={props.assetType}
                                    imageURL={props.imageURL}
                                    altText={props.altText}
                                    addlWrapperClassnames={props.mainImageWrapperClassnames}
                                />   
                                <PortfolioProjectAccoladeItems
                                    items={props.accoladeItems}
                                    itemWrapperClassnames={['col-6', 'project-accolade__col']}
                                /> 
                            </div>
                        </div>
                    </div>
                </blockquote>
            </div>
        )
    } else {
        return null;
    }
}

export default PortfolioProjectAccolade