import React from "react"


const HomeSlide = (props) => (
  <article
    className={'projects-gallery__slide darken--middle js-slide' + (props.itemNum > 0 ? ' after-active' : '' )+(props.defaultActive ? ' is-active' : '')}
    data-handle={props.portfolioItemShortHandle}
  >
    <div
      className="projects-gallery__slide-img"     
      style={{backgroundImage: `url(${props.portfolioItemHomeSlideImage.url})`}}
    ></div>    
    
    <div className="projects-gallery__slide-inner">
        <h2 dangerouslySetInnerHTML={{ __html: props.portfolioItemClientName.json.content[0].content[0].value }} />
        <h1 dangerouslySetInnerHTML={{ __html: props.portfolioItemHomeSlideTitle.json.content[0].content[0].value }} />    
        <a
          href={"/work/"+props.portfolioItemDetailPageSlug}
          className="button projects-gallery__btn"
        >
          <span className="text-wrap">View</span>
        </a>
    </div>                
  </article>
)

export default HomeSlide