import React from "react"
import PortfolioWebsiteGroupItem from './WebsiteGroupItem'


/*** begin STATEFUL FUNCTIONS.. */
const PortfolioWebsiteGroup = (props) => {

    if (props.itemsInGroup) {  

        let finalClassnames = []        

        if (props.parentGroupType==='ipad-pro') {
            finalClassnames.push('image-website-group__wrap--ipad-pro')
        } else if (props.parentGroupType==='iphone-x') {
            finalClassnames.push('image-website-group__wrap--mobile-x')
        }

        if (props.baseClassnames) {
            props.baseClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }

        if (props.additionalClassnames) {
            props.additionalClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }

        if (!props.itemsInGroup) {
            return (null)
        }

        let innerMarkup = null,
            standaloneItems = []

        props.itemsInGroup.forEach( item => {
            if (item.websiteGroupItemType==='sticky-nav-element' &&
                item.websiteGroupItemImage.url)
            {
                /**
                 * this item is just an element to be displayed along with the main element.
                 * yes, given the var assignment below, we're only assuming
                 * one of these kinds of elements will exist.
                 * A little hacky but this was a really special case that had to be accommon
                 * **/
                innerMarkup =  '<div class="image-website-group__nav--bottom"><img src="'+item.websiteGroupItemImage.url+'" /></div>'
            } else {
                // add item to final array
                standaloneItems.push(item)
            }
        })

        return (
            <div className={finalClassnames.join(' ')}>
                {standaloneItems.map((item, i) =>
                    <PortfolioWebsiteGroupItem
                        key={i}
                        imageURL={item.websiteGroupItemImage.url}
                        altText={item.websiteGroupItemImage.title}
                        imageCaption={item.websiteGroupItemImage.description}
                        isVerticallyScrollable={item.isVerticallyScrollable}
                        parentType={props.parentGroupType}
                        baseClassnames={['image']}
                        additionalInnerMarkup={innerMarkup}
                        showCaptions={props.showCaptions}
                    />
                )}
            </div>
        )
        
    } else {
        return null
    }
}

export default PortfolioWebsiteGroup