import React from "react"
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

/*** begin STATELESS FUNCTIONS */
const PullquoteAttribute = ({ children }) => children
const PullquoteAttributeTitle = ({ children }) => <span className="client-quote__attr-title">{children}</span>
const PullquoteHyperlink = ({ children }) => <a href={children[0].data.uri} target="_blank" rel="noreferrer">{children[0].content[0].value}</a>
//// ^^ i cant say i understand the arguments passed to PullquoteHyperlink, but this works.

const getRawPullquote = (json) => {
    return (
        <h1 dangerouslySetInnerHTML={{ __html: json.content[0].content[0].value }} />
    )
}

const pullquoteAttributeOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <PullquoteAttribute>{children}</PullquoteAttribute>,
      [INLINES.HYPERLINK]: (node, children) => <PullquoteHyperlink>{node}{children}</PullquoteHyperlink>,
    },
};

const pullquoteAttributeTitleOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <PullquoteAttributeTitle>{children}</PullquoteAttributeTitle>,
    },
};

const PullquoteAttributeTitleComponent = props => {
    if (props.attributeTitleText) {
        return documentToReactComponents(props.attributeTitleText.json, pullquoteAttributeTitleOptions)         
    }
    return null 
}

const PullquoteAttributeComponent = props => {
    if (props.attributeText) {
        return  <p className="client-quote__attr">
                    {documentToReactComponents(props.attributeText.json, pullquoteAttributeOptions)}
                    {PullquoteAttributeTitleComponent(props)}
                </p>
    }
    return null 
}

const renderPullQuoteAttribution = props => {
    if (props.quoteText) {
        return PullquoteAttributeComponent(props)
    }
    return null;
}

const PortfolioPullQuoteAsset = (props) => {
    if (props.quoteText) {     
        return (
            <div className="client-quote has-bleed-bg project-color-bg js-load-in">
                <blockquote>
                    <div className="client-quote__inner js-load-in">
                        <div className="container">
                            <div className="row center">
                                <div className="col-12">  
                                    {getRawPullquote(props.quoteText.json)}
                                    {renderPullQuoteAttribution(props)}
                                </div>
                            </div>
                        </div>
                    </div>                     
                </blockquote>              
            </div>
        )
    } else {
        return null;
    }
}

export default PortfolioPullQuoteAsset