import React, { useState, useEffect, useRef } from "react"

const GlobalLogin = (props) => {

  const [loggedIn, setloggedIn] = useState(null)
  const [status, setStatus] = useState(null)
  const [submitCount, setSubmitCount] = useState(0)
  const passwordField = useRef()
  const wrapper = useRef()

  const handleClick = (e) => {
    e.preventDefault()

    let prevCount = submitCount
    prevCount++
    setSubmitCount(prevCount)

    
    if (!passwordField.current.value || passwordField.current.value!==localStorage.getItem('password')) {
      setStatus('invalid '+submitCount)
      wrapper.current.classList.add('is-invalid')
    } else if (passwordField.current.value===localStorage.getItem('password')) {
      setStatus('valid')
      setloggedIn(true)
      //sessionStorage.setItem('isLoggedIn', true) // causes login on new tab 
      localStorage.setItem('isLoggedIn', true)
      document.querySelector('body').classList.remove('login-page')
      wrapper.current.classList.remove('is-invalid')
    }
  }

  useEffect( () => {
   
    if (localStorage.getItem('isLoggedIn')) {
      setloggedIn(true)
      //remove body classes as an "outro" — so the next page can have a load-in appearance
      document.querySelector('body').classList.remove('bare-display')
      document.querySelector('body').classList.remove('loaded')
    } else {
      setloggedIn(false)
      document.querySelector('body').classList.add('login-page')
      setTimeout( () => document.querySelector('body').classList.add('bare-display'), 300)
      setTimeout( () => document.querySelector('body').classList.add('loaded'), 600)  
    }
    
    // hacky but had to do it since setStatus wasnt causing 
    let pwform = document.querySelector('.pw-form__pw')

    if (pwform) {
      setTimeout( () => pwform.classList.remove('is-invalid'), 200)
    }

  }, [setloggedIn, status])

  if (loggedIn) {
    return props.children // all other components in App.js
  }
  
  return (    
    <div className="site-container">
      <div className="pw-form">
        <div className="pw-form__cell">
          <div className="pw-form__logo">
            <img src="/files/vec_logo_display.svg" alt="Veronica Camargo" />
          </div>
          <div
            className={'pw-form__pw '}
            ref={wrapper}
          >
            <form>				  
                <input
                  type="password"
                  name="password"
                  placeholder="ACCESS PASSWORD"
                  className="pw-form__field"
                  autoComplete="off"
                  ref={passwordField}
                />		
                <div className="pw-form__btn-wrap">				
                  <button
                    type="submit"
                    onClick={handleClick}
                    className="btn pw-form__go-btn"
                  >
                    Go
                  </button>	
                </div>			  
            </form>
          </div>
          <div className="pw-form__footer">
            <p className="pw-form__request">
              <span className="pw-form__contact">
              <a href="mailto:veronica@veronicacamargo.com?subject=Requesting Access to Your Website">
                Contact me
              </a> to request access.</span> If you have my résumé, a password is located at the top of the page.
            </p>
          </div>  
        </div>
      </div>
    </div>
  )  
}

export default GlobalLogin