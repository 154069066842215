import React, { useEffect } from "react"
import Slider from "react-slick"
import PortfolioCaption from './Caption'
import PortfolioSectionHeading from './SectionHeading'
import {
    tabletMocksAspectRatio, 
    phoneMocksAspectRatio
} from '../../globalFunctionObjects.js'


/*** begin STATEFUL FUNCTIONS.. */
const PortfolioSliderAsset = (props) => {

    const slideLoop = props.sliderItems.map((slide, index) => {

        let finalContent = null,
            slideCaption = null

        if (slide.description && props.showCaptions===true) {
            slideCaption = slide.description
        }

        if (props.assetType==='slider-ipad' ||
            props.assetType==='slider-iphone') {

                let addClassnames = ['vp-wrap']

                if (props.isVerticallyScrollable) {
                    addClassnames = [...addClassnames, ['is-scrollable']]
                }

                if (slideCaption) {
                    finalContent =  (<div
                                        className="image__inner"
                                        key={index}     
                                    >
                                        <PortfolioCaption caption={slideCaption} />
                                        <div className={addClassnames.join(' ')}>
                                            <img src={slide.url} alt={slide.title} />                
                                        </div>
                                    </div>
                                    )
                } else {
                    finalContent =  (<div className={addClassnames.join(' ')} key={index}>
                                    <img src={slide.url} alt={slide.title} />                
                                </div>)
                }                
        } else {
            finalContent =  <img src={slide.url} alt={slide.title} key={index} />
        }

        return  (finalContent)       
    })

    useEffect(() => {
        // code to run after render goes here        
        // always add event listeners within useEffect so it's run after render
        // and also removed (unbound) on unMount   
        if (props.assetType==='slider-ipad') {
            tabletMocksAspectRatio.init()     
            window.addEventListener('resize', tabletMocksAspectRatio.update) 
        } else {
            phoneMocksAspectRatio.init()
            window.addEventListener('resize', phoneMocksAspectRatio.update)  
        }        
    }, [props.assetType])

    
    if (props.sliderItems) {   
        
        let finalClassnames = ['has-bleed-bg']
        let slickClassnames = []
        let slickSettings = {}

        if (props.assetType==='slider-iphone') {
            slickClassnames = [                    
                'iphone-center-infinite',
                'mobile-x',
            ]
            slickSettings = {
                centerMode: false,
                centerPadding: '2%',
                slidesToShow: 4, // strive for odd number so active one is centered
                arrows: false,
                infinite: true,
                dots: false,
                cssEase: 'ease-in-out',
                responsive: [
                    {
                        breakpoint: 2000, // < 1800
                        settings: {
                            slidesToShow: 3, // strive for odd number so active one is centered
                            arrows: false,
                        }
                    },
                    {
                        breakpoint: 1100, // < 1800
                        settings: {
                            slidesToShow: 2, // strive for odd number so active one is centered
                            centerMode: false,
                            // if odd number, center mode has to be true so active slide is in middle.
                            arrows: false,
                        }
                    },
                    {
                        breakpoint: 600, // < 500
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                            // if odd number, center mode has to be true so active slide is in middle.
                        }
                    },                    
                ]
            }
        }

        if (props.assetType==='slider-center-finite') {
            slickClassnames = [                    
                'center',
            ]
            slickSettings = {
                centerMode: true,
                centerPadding: '2%',
                slidesToShow: 1,
                arrows: false,
                infinite: true,
                dots: false,  
                responsive: [
                    {
                        breakpoint: 2000,
                        settings: {
                            slidesToShow: 2,
                            centerMode: false,
                        }
                    },
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                        }
                    }, 
                ]              
            }
        }

        if (props.assetType==='slider-ipad') {
            slickClassnames = [                    
                'ipad-pro',
                'ipad-center-infinite',
            ]

            slickSettings = {
                centerMode: false,
                centerPadding: '2%',
                slidesToShow: 2,
                arrows: false,
                infinite: true,
                dots: false,
                responsive: [
                    {
                        breakpoint: 2000, // < 1800
                        settings: {
                            slidesToShow: 2,                            
                            centerMode: false,
                            // if even number, center mode has to be false so active slide is on left.
                        }
                    },
                    {
                        breakpoint: 1400, // < 1800
                        settings: {
                            slidesToShow: 1, // strive for odd number so active one is centered
                            centerMode: true,
                            // if odd number, center mode has to be true so active slide is in middle.
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                        }
                    },  
                ]
            }
        }

        if (props.assetType==='slider-center-infinite') {
            slickClassnames = [                    
                'center-infinite',
            ]

            slickSettings = {
                centerMode: true,
                centerPadding: '2%',
                slidesToShow: 2,
                arrows: false,
                infinite: true,
                dots: false,
                responsive: [                    
                    // {
                    //     breakpoint: 2000,
                    //     settings: {
                    //         slidesToShow: 2,
                    //         centerMode: false,
                    //     }
                    // },
                    {
                        breakpoint: 1600,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                        }
                    }, 
                ]     
            }
        }   


        if (props.assetType==='slider-center-infinite--twoup-sq') {
            slickClassnames = [                    
                'center-infinite--twoup-sq',
            ]

            slickSettings = {
                centerMode: true,
                centerPadding: '2%',
                slidesToShow: 2,
                arrows: false,
                infinite: true,
                dots: false,
                responsive: [
                    {
                        breakpoint: 2000,
                        settings: {
                            slidesToShow: 2,
                            infinite: true,
                            centerMode: false,
                        }
                    },
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 2,
                            infinite: true,
                            centerMode: false,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                            arrows: false,
                        }
                    }, 
                ]     
            }
        }   

        finalClassnames = [...finalClassnames, ...slickClassnames]
        
        /** 
         * props.addlWrapperClassnames and props.baseClassnames
         * are NOT not truly arrays. They're typeof array BEFORE
         * being set as a prop but become objects once set as prop
         * so we can't just spread the arrays, we have to loop and push.
         * */
        if (props.baseClassnames) {
            props.baseClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        } 
        
        if (props.addlWrapperClassnames) {
            props.addlWrapperClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }    

        return (
            <div className={finalClassnames.join(' ')}>
                <PortfolioSectionHeading
                    sectionHeading={props.sectionHeading}                    
                />                                
                <Slider 
                    {...slickSettings}
                    className="use-js-cursor"
                >
                    {slideLoop}
                </Slider>    
            </div>
        )
    } else {
        return null;
    }
}

export default PortfolioSliderAsset