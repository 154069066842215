import React from "react"


const PortfolioCaption = (props) => {
    if (props.caption) {
        return (        
            <div className="caption-wrap">
                <div className="info-caption">
                    <div className="info-caption__inner">
                        <p className="caption-text">{props.caption}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default PortfolioCaption