import React, { useEffect } from "react"
import PortfolioCoreBrandingAsset from './CoreBrandingAsset'
import PortfolioImageGridAsset from './ImageGridAsset'
import PortfolioProjectAccolade from './ProjectAccolade'
import PortfolioPullQuoteAsset from './PullQuoteAsset'
import PortfolioSingleBackgroundImageAsset from './SingleBackgroundImageAsset'
import PortfolioSingleImageAsset from './SingleImageAsset'
import PortfolioImageGroupStaggeredAsset from './ImageGroupStaggeredAsset'
import PortfolioSliderAsset from './SliderAsset'
import PortfolioWebsiteSuperGroupAsset from './WebsiteSuperGroupAsset'
import { loadInEffect, customCursor } from '../../globalFunctionObjects.js'

const PortfolioAssetsHandler = (props) => {

    const assetTypeSwitch = props.portAssets.map((asset, index) => {

        let finalContent = null

        switch (asset.assetType) {            
            case 'pullquote':
                finalContent = (
                    <PortfolioPullQuoteAsset 
                        key={index}
                        quoteText={asset.portfolioItemAssetQuoteText}
                        attributeText={asset.portfolioItemAssetQuoteAttributeText}
                        attributeTitleText={asset.portfolioItemAssetQuoteAttributeTitleText}
                        addlWrapperClassnames={asset.wrapperClassnames}
                    />
                )
            break
            case 'project-accolade':
                finalContent = (
                    <PortfolioProjectAccolade 
                        key={index}
                        imageURL={asset.mainImage.url}
                        altText={asset.mainImage.title}
                        accoladeItems={asset.accoladeItemsCollection.items}
                        addlWrapperClassnames={asset.wrapperClassnames}
                        mainImageWrapperClassnames={['col-12', 'project-accolade__col']}
                    />
                )
            break
            case 'slider-ipad':
            case 'slider-iphone':
            case 'slider-center-finite':
            case 'slider-center-infinite':
            case 'slider-center-infinite--twoup-sq':   
                finalContent = (
                    <PortfolioSliderAsset
                        key={index}
                        assetType={asset.assetType}
                        sliderItems={asset.portfolioItemAssetImageSetImagesCollection.items}
                        isVerticallyScrollable={asset.portfolioItemIsVerticallyScrollable}
                        baseClassnames={['slider', 'js-load-in']}
                        addlWrapperClassnames={asset.wrapperClassnames}
                        sectionHeading={asset.sectionHeading}
                        showCaptions={asset.showCaptions}
                    />
                )
            break
            case 'single-image':
                if (asset.optionalBackgroundImage && asset.optionalBackgroundImage.url) {
                    finalContent = (
                        <PortfolioSingleBackgroundImageAsset
                            key={index}
                            imageURL={asset.optionalBackgroundImage.url}
                            baseClassnames={['has-bleed-bg', 'image', 'bg-cover--main']}
                        >
                            <PortfolioSingleImageAsset
                                key={index}
                                assetType={asset.assetType}
                                imageURL={asset.portfolioItemAssetImageSetImagesCollection.items[0].url}
                                altText={asset.portfolioItemAssetImageSetImagesCollection.items[0].title}
                                baseClassnames={['has-bleed-bg', 'image', 'js-load-in']}
                                addlWrapperClassnames={asset.wrapperClassnames}
                            />
                        </PortfolioSingleBackgroundImageAsset>
                    )
                } else {
                    finalContent = (
                        <PortfolioSingleImageAsset
                            key={index}
                            assetType={asset.assetType}
                            imageURL={asset.portfolioItemAssetImageSetImagesCollection.items[0].url}
                            altText={asset.portfolioItemAssetImageSetImagesCollection.items[0].title}
                            baseClassnames={['has-bleed-bg', 'image', 'js-load-in']}
                            addlWrapperClassnames={asset.wrapperClassnames}
                            caption={asset.portfolioItemAssetImageSetImagesCollection.items[0].description}
                            showCaptions={asset.showCaptions}
                            sectionHeading={asset.sectionHeading}
                        />                        
                    )   
                }         
            break
            case 'single-bg-image':
                finalContent = (
                    <PortfolioSingleBackgroundImageAsset
                        key={index}
                        imageURL={asset.portfolioItemAssetImageSetImagesCollection.items[0].url}
                        altText={asset.portfolioItemAssetImageSetImagesCollection.items[0].title}
                        baseClassnames={['has-bleed-bg', 'image', 'bg-cover--main', 'js-load-in']}
                        addlWrapperClassnames={asset.wrapperClassnames}
                    />
                )
            break
            case 'website-supergroup--tablet-tablet':
            case 'website-supergroup--tablet-phone':
            case 'website-supergroup--tablet-phone-phone':
                finalContent = (
                    <PortfolioWebsiteSuperGroupAsset
                        key={index}
                        assetType={asset.assetType}
                        groupItems={asset.websiteSubGroupsCollection.items}
                        baseClassnames={['image-website-group', 'has-bleed-bg', 'js-load-in']}
                        addlWrapperClassnames={asset.wrapperClassnames}
                        showCaptions={asset.showCaptions}
                        superGroupCaptionText={asset.superGroupCaptionText}
                        sectionHeading={asset.superGroupSectionHeading}
                    />
                )
            break
            case 'grid--two-up':
            case 'grid--four-up':
                finalContent = (
                    <PortfolioImageGridAsset
                        key={index}
                        assetType={asset.assetType}
                        gridItems={asset.portfolioItemAssetImageSetImagesCollection.items}
                        baseClassnames={['image-group', 'has-bleed-bg', 'js-load-in']}
                        addlWrapperClassnames={asset.wrapperClassnames}
                        sectionHeading={asset.sectionHeading}
                    />
                )          
            break
            case 'group-staggered--left-initial':
            case 'group-staggered--right-initial':
                finalContent = (
                    <PortfolioImageGroupStaggeredAsset
                        key={index}
                        assetType={asset.assetType}
                        gridItems={asset.portfolioItemAssetImageSetImagesCollection.items}
                        baseClassnames={['group-staggered', 'has-bleed-bg', 'js-load-in']}
                        addlWrapperClassnames={asset.wrapperClassnames}
                        sectionHeading={asset.sectionHeading}
                    />
                )           
            break
            case 'brand-assets':
                finalContent = (
                    <PortfolioCoreBrandingAsset
                        key={index}
                        assetType={asset.assetType}
                        logoImageURL={asset.logo.url}
                        logoImageAltText={asset.logo.title}
                        paletteItems={asset.brandPalettesCollection.items}
                        fontItems={asset.brandFontsCollection.items}
                        iconItems={asset.brandIconsCollection.items}
                        sectionHeading={asset.sectionHeading}
                    />
                )
            break
            default:
                return null
        }

        return ( finalContent )       
    })

    useEffect(() => {        
        setTimeout( () => loadInEffect.init(), 500)
        window.addEventListener('scroll', loadInEffect.reveal)
        customCursor.init()
    })

    if (props.portAssets && props.portAssets instanceof Array) {  
        return (
            assetTypeSwitch           
        )
    } else {
        return null
    }
}

export default PortfolioAssetsHandler