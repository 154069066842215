import React, { useEffect, useState } from "react"
//import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Route, Switch } from "react-router-dom"
import GlobalHeader from './components/Global/Header'
import GlobalLoading from './components/Global/Loading'
import GlobalLogin from './components/Global/Login'
import AboutIndex from './components/About/Index'
import HomeIndex from './components/Home/Index'
import PortfolioIndex from './components/Portfolio/Index'
import './style.css'
import { dimensions, addTouchStatusClass } from './globalFunctionObjects.js'

const App = () => {

  const [loggedIn] = useState(null)

  useEffect(() => {
    // code to run after render goes here    
    // always add event listeners within useEffect so it's run after render
    // and also removed (unbound) on tear-down
    // the following will be included on any route/page ("global" functions)
    window.addEventListener('resize', dimensions.update)
    window.addEventListener('resize', dimensions.setViewportVar)
    window.addEventListener('scroll', dimensions.updateScrollDetails)     
    dimensions.init()
    dimensions.setBodyHeight()
    addTouchStatusClass()
    localStorage.setItem('password', 'guest2021')
  })

  return (
      <div className="App">        
        <GlobalLogin
          isAlreadyLoggedIn={loggedIn}
        >
          <GlobalHeader />
          <Switch>
            <Route 
              exact
              path="/"
              component={() => {
                return <HomeIndex />
              }}
            />
            <Route 
              exact
              path="/work"
              component={() => {
                return <HomeIndex scrollToWork={true} />
              }}
            />
            <Route
              path="/work/:portfolioItemSlug"
              component={() => {
                return <PortfolioIndex />
              }}
            />
            <Route
              path="/about/:genPageSlug"
              component={() => {
                return <AboutIndex />
              }}
            />
            <GlobalLoading />
          </Switch>
        </GlobalLogin>
      </div>
  )
}

export default App