import React from "react"
import { setup as reactContentfulImageSetup, getBackgroundImageCss } from 'react-contentful-image'
import {responsiveImages, responsiveImageMediaQueries as media} from '../../globalFunctionObjects.js'

const variants = responsiveImages.variants
const bgImageSizes = responsiveImages.backgroundImageSizes

/*** begin STATELESS VARS / FUNCTIONS... */

reactContentfulImageSetup(media, variants)
// reference: https://developer.aliyun.com/mirror/npm/package/react-contentful-image

/*** begin STATEFUL FUNCTIONS.. */
const PortfolioSingleBackgroundImageAsset = (props) => {

    if (props.imageURL) {                  
        const   protocol_agnostic_contentfuL_file_url = props.imageURL.split('//')[1],
                imageUrl = '//'+protocol_agnostic_contentfuL_file_url,
                bgImagesClass = getBackgroundImageCss(imageUrl, bgImageSizes)

        let finalClassnames = []  
        
        if (props.baseClassnames) {
            props.baseClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }

        if (props.addlWrapperClassnames) {
            props.addlWrapperClassnames.forEach( addClass => {
                finalClassnames.push(addClass)
            })
        }
        
        finalClassnames = [...finalClassnames, bgImagesClass]        

        return (
            <div 
                className={finalClassnames.join(' ')}
                role="img"
                aria-label={props.altText}
            >
                {props.children}
            </div>
        )
        
    } else {
        return null;
    }
}

export default PortfolioSingleBackgroundImageAsset