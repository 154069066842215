import React, {useState, useEffect} from "react"


const queryPortfolioCollection = `
{
  portfolioItemCollection(order: [portfolioItemHomeOrderBy_ASC]) {
    items {
      portfolioItemClientName {
        json
      }
      portfolioItemShortHandle
      portfolioItemDetailPageSlug
      portfolioItemNavIcon {
        url
      }      
      portfolioItemHomeSlideImage {
        url
      }
      portfolioItemHomeSlideTitle {
        json
      }
      portfolioItemDetailPageTitle {
        json
      }
    }
  }
}
`

const PortfolioPrevNext = (props) => {

  const [portfolioNavItems, setPortfolioNavItems] = useState(null, null)

  useEffect(() => {
    window
    .fetch(`https://graphql.contentful.com/content/v1/spaces/qxgsbxqyjsra/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer kHvjHpwOCN2ugb3mTXreQ_IRWmvvTYQ9jfQt27N7J9I",
    },
    body: JSON.stringify({ query: queryPortfolioCollection }),
    })
    .then((response) => response.json())
    .then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        //console.error(errors)
      } else if (data) {
        // rerender the entire component with new data
        setPortfolioNavItems(data.portfolioItemCollection.items)
      }  
    });
  }, [setPortfolioNavItems])

  if (!portfolioNavItems) {
    return null
  }

  const getNextNavItem = () => {   
    let firstNavItem = null,
        returnNext = false
    
    for (var item of portfolioNavItems) {
      if (!firstNavItem) {
        firstNavItem = item
      }
      if (returnNext===true) {
        return item
      }
      if (item.portfolioItemDetailPageSlug===props.currentPageSlug){
        returnNext = true
      }      
    }

    return firstNavItem // will return the first portfolio item (nav loops around)         
  }

  const getPrevNavItem = () => {   
    let prevNavItem = null

    for (var item of portfolioNavItems) {
      if (prevNavItem && item.portfolioItemDetailPageSlug===props.currentPageSlug){
        return prevNavItem
      }            
      prevNavItem = item
    }

    return prevNavItem // will return the last portfolio item (nav loops around)         
  }

  const getNavItemsContent = () => {
    return (
      portfolioNavItems.map((item, i) =>          
          <span    
            key={i} 
            data-handle={item.portfolioItemShortHandle}
            className={props.currentPageSlug===item.portfolioItemDetailPageSlug ? 'current' : 'undefined'}
          >
            <a href={"/work/"+item.portfolioItemDetailPageSlug}>
              <img src={item.portfolioItemNavIcon.url} alt={item.portfolioItemClientName} />
            </a>     
          </span>
      )
    )
  }

  const getPrevNextNavItemContent = (direction='prev') => {
    let prevNextNavItem = getPrevNavItem(), // default to prev
        directionClassLetter = 'l'

    if (direction==='next') {
      prevNextNavItem = getNextNavItem()
      directionClassLetter = 'r'
    }

    if (prevNextNavItem) {
      return (
        <div className={'prev-next__'+directionClassLetter}>
          <a
            href={'/work/'+prevNextNavItem.portfolioItemDetailPageSlug} 
            className="prev-next__link"
          >
            <span
              className={'prev-next__text prev-next__'+direction}
            >
              {direction}
            </span>
          </a>
      </div>
      )
    }
    return null      
  }

  if (portfolioNavItems) {
    return (
      <div className="prev-next">
        <nav className="prev-next__inner">
          <div className="prev-next__items">                      
            {getPrevNextNavItemContent('prev')}
            <div className="prev-next__m">
              <div className="prev-next__m__inner">
                {getNavItemsContent()}
              </div>
            </div>
            {getPrevNextNavItemContent('next')}
          </div>
        </nav>
      </div>
    )
  }
}

export default PortfolioPrevNext