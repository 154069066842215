/**
 * Global JS functions bound to window
 * 
 * NOT state-based
 * 
 * to be used by multiple components 
 * 
 * 
 */

let resizeTimer;

window.addEventListener("resize", () => {
  document.body.classList.add("resize-animation-stopper");
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    document.body.classList.remove("resize-animation-stopper");
  }, 600);
});


const dimensions = {
  winWidth: 'undefined',
  winHeight: 'undefined',
  docWidth: 'undefined',
  docHeight: 'undefined',
  scrollDirection: 'down',
  lastScrollPos: window.pageYOffset,
  scrollPosIsHalfwayThroughTopPane: false,
  scrollPosInLastViewablePane: false,
  init: () => {
    dimensions.update() 
    dimensions.setViewportVar()  
  },
  __getViewportVarValue: () => (window.innerHeight * 0.01),
  setViewportVar: () => {
    // JS / CSS variable to address viewport height on iOS
    // (when toolbar visible, viewport height includes toolbar)
    // This is critical at least for elements that have their heights set
    // or a transform (translateY) set as 100vh or any calculation involving 100vh   
    let vh = dimensions.__getViewportVarValue()
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
  updateScrollDetails: () => {    
    // update details about where scroll is, in relation to main sections
    const halfwayPoint = (dimensions.winHeight / 2)
    const pageOverflow = (dimensions.docHeight - dimensions.winHeight)

    // update scroll direction
    if (window.pageYOffset >= dimensions.lastScrollPos) {
      dimensions.scrollDirection = 'down'
    } else {
      dimensions.scrollDirection = 'up'
    }    
    dimensions.lastScrollPos = window.pageYOffset

    if (window.pageYOffset >= halfwayPoint) {   
      dimensions.scrollPosIsHalfwayThroughTopPane = true
    } else {
      dimensions.scrollPosIsHalfwayThroughTopPane = false
    }

    if (pageOverflow > 0 && window.pageYOffset >= pageOverflow) {   
      dimensions.scrollPosInLastViewablePane = true
    } else {
      dimensions.scrollPosInLastViewablePane = false
    }
  },
  setBodyHeight: () => {
    document.querySelector('body').style.height = dimensions.winHeight+'px'
  },
  update: () => {
    dimensions.winWidth = dimensions.viewport().width
    dimensions.winHeight = window.innerHeight
    dimensions.docWidth = document.body.clientWidth
    dimensions.docHeight = document.documentElement.scrollHeight
    dimensions.setBodyHeight()
  },
  viewport: () => {
      let e = window,
          a = 'inner'

      if (!('innerWidth' in window)) {
          a = 'client'
          e = document.documentElement || document.body
      }
      return {
          width: e[a + 'Width'],
          height: e[a + 'Height']
      }
  },
  getWindowWidth: () => dimensions.winWidth,
  getWindowHeight: () => dimensions.winHeight,
  getDocumentHeight: () => dimensions.docHeight,
  getDocumentWidth: () => dimensions.docWidth
}



const tabletMocksAspectRatio = {
  interval: '',
  slickLoaded: false,
  wrapperClassname: 'ipad-pro',
  slideClassname: 'vp-wrap',
  deviceAspectRatio:(1024 / 1366),
  // rounded ratio for 0.749633967789165 (ratio of viewport height of 1024 / viewport width of 1366)
  init: () => {
    setTimeout( () => tabletMocksAspectRatio.setHeights(), 900)
    setTimeout( () => tabletMocksAspectRatio.setHeights(), 3000)
  },
  update: () => {    
      tabletMocksAspectRatio.beforeUpdate().setHeightsTimeout().afterUpdate()   
  },
  beforeUpdate: () => {
    // capture current height of slicktrack....
    const els = document.querySelectorAll('.'+tabletMocksAspectRatio.wrapperClassname+' .slick-track ')

    if (els && els.length) {
      els.forEach((el, index) => {
          el.style.maxHeight = el.clientHeight+'px'
          //el.classList.add('resize-progress')
      })
    }

    return tabletMocksAspectRatio
  },
  afterUpdate: () => {
    setTimeout( () => {
      const els = document.querySelectorAll('.'+tabletMocksAspectRatio.wrapperClassname+' .slick-track ')
      if (els && els.length) {
        els.forEach((el, index) => {
            el.style.maxHeight = null
            //el.classList.remove('resize-progress')
        })
      }
    }, 1200)  
    return tabletMocksAspectRatio
  },
  setHeights: () => {
    const els = document.querySelectorAll('.'+tabletMocksAspectRatio.wrapperClassname+' .'+tabletMocksAspectRatio.slideClassname+' img')

    if (els && els.length > 0) {
      els.forEach((el, index) => {
        const newHeight = Math.ceil(el.clientWidth * tabletMocksAspectRatio.deviceAspectRatio),
              vpWrapEl = el.closest('.'+tabletMocksAspectRatio.slideClassname),
              optionalCaptionWrap = el.closest('.image__inner')

        if (vpWrapEl) {
          vpWrapEl.style.height = newHeight+'px'
        }
        if (optionalCaptionWrap) {
          optionalCaptionWrap.style.height = newHeight+'px'
        }
      })
    } 
    return tabletMocksAspectRatio
  },
  setHeightsTimeout: () => {
    setTimeout( () => tabletMocksAspectRatio.setHeights(), 600)   
    return tabletMocksAspectRatio
  }
}



const phoneMocksAspectRatio = {
  interval: '',
  slickLoaded: false,
  wrapperClassname: 'mobile-x',
  slideClassname: 'vp-wrap',
  deviceAspectRatio: (812 / 375),
  // rounded ratio of 2.165333333333333 (viewport height of 812 / viewport width of 375)
  init: () => {
    setTimeout( () => phoneMocksAspectRatio.setHeights(), 900)
    setTimeout( () => phoneMocksAspectRatio.setHeights(), 3000)
  },
  update: () => {
    phoneMocksAspectRatio.beforeUpdate().setHeightsTimeout().afterUpdate()   
    //setTimeout( () => phoneMocksAspectRatio.setHeights(), 300)
    // note: slick-slider OR its immediate parent will get .loaded at some point
    // come back to this later to set an array of intervals (one item per slider)
    // that runs setHeights once the .loaded class has been added. 
  },
  beforeUpdate: () => {
    // capture current height of slicktrack....
    const els = document.querySelectorAll('.'+phoneMocksAspectRatio.wrapperClassname+' .slick-track ')

    if (els && els.length) {
      els.forEach((el, index) => {
          el.style.maxHeight = el.clientHeight+'px'
          //el.classList.add('resize-progress')
      })
    }

    return phoneMocksAspectRatio
  },
  afterUpdate: () => {
    setTimeout( () => {
      const els = document.querySelectorAll('.'+phoneMocksAspectRatio.wrapperClassname+' .slick-track ')
      if (els && els.length) {
        els.forEach((el, index) => {
            el.style.maxHeight = null
            //el.classList.remove('resize-progress')
        })
      }
    }, 1200)  
    return phoneMocksAspectRatio
  },
  setHeights: () => {
    const els = document.querySelectorAll('.'+phoneMocksAspectRatio.wrapperClassname+' .'+phoneMocksAspectRatio.slideClassname+' img')

    if (els && els.length > 0) {
      els.forEach((el, index) => {
        const newHeight = Math.ceil(el.clientWidth * phoneMocksAspectRatio.deviceAspectRatio),
              vpWrapEl = el.closest('.'+phoneMocksAspectRatio.slideClassname),
              optionalOuterCaptionWrap = el.closest('.image__inner')
   
        if (vpWrapEl) {
          vpWrapEl.style.height = newHeight+'px'
        }

        if (optionalOuterCaptionWrap) {
          optionalOuterCaptionWrap.style.height = newHeight+'px'
          optionalOuterCaptionWrap.classList.add('resizing')
        }
      })
    } 
  },
  setHeightsTimeout: () => {
    setTimeout( () => phoneMocksAspectRatio.setHeights(), 600)   
    return phoneMocksAspectRatio
  }
}



const customCursor = {
	cursor: '',
  horizontalActivateClass: '.use-js-cursor',
  verticalActivateClass: '.image-website-group .use-js-cursor.is-scrollable',
  sliderVerticalActivateClass: '.use-js-cursor .slick-slide .vp-wrap.is-scrollable',
	initshow: false,
	init: () => {
    const createCursorDiv = document.createElement("div")
    createCursorDiv.classList.add('js-cursor')
    document.querySelector('body').prepend(createCursorDiv)
    
    customCursor.cursor = document.querySelector('.js-cursor')
		customCursor.bindEvents()
	},
	showCursor: () => {
		document.querySelector('body').classList.add('js-cursor--show');
	},
	hideCursor: () => {
		document.querySelector('body').classList.remove('js-cursor--show');
	},
	removeHover: () => {
		customCursor.cursor.removeClass('js-cursor--hover');
	},
  __ifContainsCaption: (e) => {
    const ifContainsCaption = (e.target.classList.contains('info-caption') || e.target.classList.contains('caption-wrap') || e.target.classList.contains('caption-text'))

    if (ifContainsCaption) {      
      return true
    }
    return false
  },
	bindEvents: () => {
    // last-minute change to check if these classes are added to slick-slider and instead apply them to the direct child (slick-list)
    // b/c slick-slider has way too much padding and therefore the cursor takes over too much area
    const horizontalMouseTargets = document.querySelectorAll(customCursor.horizontalActivateClass+':not(.slick-slider), '+customCursor.horizontalActivateClass+' > .slick-list')

    //const horizontalMouseTargets = document.querySelectorAll(customCursor.horizontalActivateClass)
    const verticalMouseTargets = document.querySelectorAll(customCursor.verticalActivateClass)
    const sliderVerticalMouseTargets = document.querySelectorAll(customCursor.sliderVerticalActivateClass)

    // special case for hovering captions nested within these custom cursor targets
    const horizontalMouseTargetsCaptions = document.querySelectorAll(customCursor.horizontalActivateClass+' .info-caption, '+customCursor.horizontalActivateClass+' .caption-wrap,'+customCursor.horizontalActivateClass+' .caption-text')    

    if (customCursor.cursor) {
      window.addEventListener('mousemove', e => {
        customCursor.cursor.style.top = (e.clientY - customCursor.cursor.clientHeight / 2)+'px'
        customCursor.cursor.style.left = (e.clientX - customCursor.cursor.clientWidth / 2)+'px'
     
        if (!customCursor.initshow){
          customCursor.showCursor()
          customCursor.initshow = true
        }
      })
    }    

    window.addEventListener('mousemove', e => {   
      customCursor.showCursor()           
    })

    window.addEventListener('mouseleave', e => {
      customCursor.hideCursor()      
    })

    if (horizontalMouseTargetsCaptions) {
      horizontalMouseTargetsCaptions.forEach(el => {
        el.addEventListener('mouseenter', e => {      
            customCursor.cursor.classList.add('js-cursor--over-caption')          
        })
        el.addEventListener('mouseleave', e => {          
          customCursor.cursor.classList.remove('js-cursor--over-caption')         
        })
      })
    }

    if (horizontalMouseTargets) { 
      horizontalMouseTargets.forEach(el => {
        el.addEventListener('mouseenter', e => {         
          if (customCursor.__ifContainsCaption(e)) {
            customCursor.cursor.classList.add('js-cursor--over-caption')
          }
          customCursor.cursor.classList.add('js-cursor--horizontal')
        })
        el.addEventListener('mouseleave', e => {
          if (customCursor.__ifContainsCaption(e)) {
            customCursor.cursor.classList.remove('js-cursor--over-caption')
          }
          customCursor.cursor.classList.remove('js-cursor--horizontal')
        })
      })
    }
    
    if (verticalMouseTargets) {
      verticalMouseTargets.forEach(el => {
        el.addEventListener('mouseenter', e => {
          if (customCursor.__ifContainsCaption(e)) {
            customCursor.cursor.classList.add('js-cursor--over-caption')
          }
          customCursor.cursor.classList.add('js-cursor--vertical')
        })
        el.addEventListener('mouseleave', e => {
          if (customCursor.__ifContainsCaption(e)) {
            customCursor.cursor.classList.remove('js-cursor--over-caption')
          }
          customCursor.cursor.classList.remove('js-cursor--vertical')
        })
      })
    }

    if (sliderVerticalMouseTargets) {
      sliderVerticalMouseTargets.forEach(el => {
        el.addEventListener('mouseenter', e => {
          if (e.target.closest('.slick-slide').classList.contains('slick-current')) {  
            if (customCursor.__ifContainsCaption(e)) {
              customCursor.cursor.classList.add('js-cursor--over-caption')
            }
            customCursor.cursor.classList.add('js-cursor--vertical')
          }
        })
        el.addEventListener('mouseleave', e => {
          if (e.target.closest('.slick-slide').classList.contains('slick-current')) {  
            if (customCursor.__ifContainsCaption(e)) {
              customCursor.cursor.classList.remove('js-cursor--over-caption')
            }
            customCursor.cursor.classList.remove('js-cursor--vertical')
          }
        })
      })
    }
	}
}

const __getElementOffset = (el) => {
  const rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop
  /** NOTE getBoundingClientRect().top is top position
   * FROM THE TOP OF THE VIEWPORT, not top of document.
   * therefore, we need to add the scrollposition to the 
   * getBoundingClientRect().top */
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}


const loadInEffect = {
  offset: 0,
  isPassed: true, // only exists to represent initial state before any scrolling has ocurred, to ensure if you're scrolled down upon loading the page, that elements are not hidden
  init: () => {
    loadInEffect.reveal()    
  },
  reveal: () => {  
    const loadinEls = document.querySelectorAll('.js-load-in')

    loadinEls.forEach((el, index) => {   
      const objectOffset = __getElementOffset(el),
            topOfObject = objectOffset.top + loadInEffect.offset,
            bottomOfWindow = window.pageYOffset + dimensions.winHeight

      if (loadInEffect.isPassed) {  
        if (
          (bottomOfWindow <= topOfObject) ||
          (loadInEffect.fixedSectionVisible===false)
          ) {
            el.classList.add('js-load-in--hidden');
        }
      } else {
        if (
          (bottomOfWindow > topOfObject) ||
          (loadInEffect.fixedSectionVisible===true)
          ) {
            el.classList.remove('js-load-in--hidden');
        }
      }
    })
    loadInEffect.isPassed = false
  }
}

const projectInfoHandler = {  
  getTabSectionMaxHeight: () => {
   
    const navEl = document.querySelector('.js-narrative-nav'),
          headingEl = document.querySelector('.project-narrative h1'),
          btnEl = document.querySelector('.project-info-back__btn'),
          tabbedNavEl = document.querySelector('.tabbed__nav'),
          containerEl = document.querySelector('.project-narrative .container.inner-pad')
    
    let navHt = 0,
        headingHt = 0,    
        btnHt = 0,
        containerTopPadding = 0,
        btnBottomPos = 0,
        desiredSpaceBetween = 40,
        maxHeightTabContent = 0

    if (navEl) {
        navHt = navEl.clientHeight
    }
    if (headingEl) {
        headingHt = headingEl.clientHeight
    }
    if (btnEl) {
        btnHt = btnEl.clientHeight

        let calcStyleBtnEl = window.getComputedStyle(btnEl)
        let calcStyleBtnElBottom = calcStyleBtnEl.getPropertyValue('bottom')
        btnBottomPos = parseInt(calcStyleBtnElBottom)

        // REALLY DUMB...
        if (dimensions.winHeight >= 900 ) {
            // calculate bottom pos of 15vh since for some reason
            // it's not getting it off the CSS MQ
            btnBottomPos = (dimensions.winHeight * 0.15)
        }
    }
    if (tabbedNavEl) {            
        let calcStyleTabbedNavEl = window.getComputedStyle(tabbedNavEl)
        let calcStyleTabbedNavElPadBot = calcStyleTabbedNavEl.getPropertyValue('padding-bottom')
        desiredSpaceBetween = parseInt(calcStyleTabbedNavElPadBot)
    }
    if (containerEl) {
        let calcStyleContainerEl = window.getComputedStyle(containerEl)
        let calcStyleContainerElPadTop = calcStyleContainerEl.getPropertyValue('padding-top')
        containerTopPadding = parseInt(calcStyleContainerElPadTop)
    }

    maxHeightTabContent = (dimensions.winHeight - containerTopPadding - desiredSpaceBetween - navHt - headingHt - btnHt - btnBottomPos)
    return Math.floor(maxHeightTabContent)
  }
}

const responsiveImages = {
  breakpoints: {
    screenXxs: '320px', // e.g., iphone 5
    screenXs: '375px', // e.g., iphone X
    screenSm: '600px',
    screenMd1: '768px', // e.g., std ipad portrait
    screenMd2: '900px',
    screenLg1: '1024px', // e.g., std ipad landscape
    screenLg2: '1280px',
    screenXl1: '1600px',
    screenXl2: '1900px'
  },
  variants: {
    default: {
        quality: 100,
        density: 1
    },
    dpr2: {
        quality: 100,
        density: 2
    },
    dpr3: {
        quality: 100,
        density: 3
    }
  },
  imageSizes: [
      {
          mediaQuery: 'default',
          params: {
              w: 320
          },
      },
      {
          mediaQuery: 'xxs',
          params: { w: 375 }
      },
      {
          mediaQuery: 'xs',
          params: { w: 600 }
      },
      {
          mediaQuery: 'sm',
          params: { w: 600 }
      },
      {
          mediaQuery: 'md1',
          params: { w: 768 }
      },
      {
          mediaQuery: 'md2',
          params: { w: 900 }
      },
      {
          mediaQuery: 'lg1',
          params: { w: 1024 }
      },
      {
          mediaQuery: 'lg2',
          params: { w: 1280 }
      },
      {
          mediaQuery: 'xl1',
          params: { w: 1600 }
      },
      {
          mediaQuery: 'xl2',
          params: { w: 1900 }
      }
  ],
  backgroundImageSizes: [
    {
        mediaQuery: 'default',
        params: {
            w: 520
        },
    },
    {
        mediaQuery: 'xxs',
        params: { w: 520 }
    },
    {
        mediaQuery: 'xs',
        params: { w: 800 }
    },
    {
        mediaQuery: 'sm',
        params: { w: 800 }
    },
    {
        mediaQuery: 'md1',
        params: { w: 968 }
    },
    {
        mediaQuery: 'md2',
        params: { w: 1100 }
    },
    {
        mediaQuery: 'lg1',
        params: { w: 1224 }
    },
    {
        mediaQuery: 'lg2',
        params: { w: 1480 }
    },
    {
        mediaQuery: 'xl1',
        params: { w: 1800 }
    },
    {
        mediaQuery: 'xl2',
        params: { w: 2100 }
    }
  ]
}


const responsiveImageMediaQueries = {
  xxs: `(min-width: ${responsiveImages.breakpoints.screenXxs})`,
  xs: `(min-width: ${responsiveImages.breakpoints.screenXs})`,
  sm: `(min-width: ${responsiveImages.breakpoints.screenSm})`,
  md1: `(min-width: ${responsiveImages.breakpoints.screenMd1})`,
  md2: `(min-width: ${responsiveImages.breakpoints.screenMd2})`,
  lg1: `(min-width: ${responsiveImages.breakpoints.screenLg1})`,
  lg2: `(min-width: ${responsiveImages.breakpoints.screenLg2})`,
  xl1: `(min-width: ${responsiveImages.breakpoints.screenXl1})`,
  xl2: `(min-width: ${responsiveImages.breakpoints.screenXl2})`,
  dpr2: '(min-resolution: 144dpi)', // 1.5x devices and up get 2x images
  dpr3: '(min-resolution: 240dpi)', // 2.5x devices and up get 3x images
  portrait: '(orientation: portrait)',
  landscape: '(orientation: landscape)'
}

const isTouchEnabled = () => ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 )

const addTouchStatusClass = () => (!isTouchEnabled() && document.querySelector('body').classList.add('no-touch'))

/*** FINALLY, export functions to be used as imports in modules */
export {
  dimensions, 
  projectInfoHandler, 
  customCursor, 
  tabletMocksAspectRatio, 
  phoneMocksAspectRatio,
  loadInEffect, 
  responsiveImages,
  responsiveImageMediaQueries,
  addTouchStatusClass
}