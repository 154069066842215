import React from "react"
import classNames from 'classnames'

const PortfolioBanner = (props) => {

    if (props.portItem) {
    
        const clientNameRaw = props.portItem.portfolioItemClientName.json.content[0].content[0].value
        const strippedClientName = clientNameRaw.replace(/<[^>]+>/g, '')

        if (props.portItem.portfolioItemDetailIcon && props.portItem.portfolioItemDetailIcon.url) {
            return (
                <div className="project-meta">  
                    <span className="project-meta__icon-wrap">
                        <img
                            className={classNames(
                            'project-meta__icon',
                                props.portItem.portfolioItemDetailIconClass
                            )}
                            alt={strippedClientName}
                            src={props.portItem.portfolioItemDetailIcon.url}
                        />
                    </span>          
                    <div className="project-meta__inner">  
                        <div className="project-meta__title-wrap inner-pad">                    
                            <div className="project-meta__title">
                                <h1 dangerouslySetInnerHTML={{ __html: props.portItem.portfolioItemHomeSlideTitle.json.content[0].content[0].value }} />     
                            </div>
                        </div>
                        <div className="project-meta__details inner-pad">
                            <div className="project-meta__item project-meta__client">
                                <span
                                    className="project-meta__label"
                                    dangerouslySetInnerHTML={{ __html: props.portItem.portfolioItemClientName.json.content[0].content[0].value }}
                                />
                            </div>                                            
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
        
    }
}

export default PortfolioBanner